import { useField } from "formik"
import React from "react"
import { BidDetailAppEngagements } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailAppEngagements"
import { BidDetailAppInstalls } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailAppInstalls"
import { BidDetailAwareness } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailAwareness"
import { BidDetailFollowers } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailFollowers"
import { BidDetailInstreamVideoAdViews } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailInStreamVideoAdViews"
import { BidDetailTPromotedVideoViews } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailTPromotedVideoViews"
import { BidDetailTweetEngagements } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailTweetEngagements"
import { BidDetailWebsiteClicks } from "src/components/organisms/AdsGroupInfoForm/BidDetail/BidDetailWebsiteClicks"
import { CampaignObjective } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  campaignName: string
  children?: never
  disabled?: boolean
  index: number
}
/**
 * キャンペーン情報で選択されたキャンペーンの目的別に予算詳細の表示を出し分けるコンポーネント
 */
export const BidDetail: React.FC<OwnProps> = ({
  campaignName,
  index,
  disabled,
}) => {
  const [field] = useField<CampaignObjective>(campaignName)

  if (field.value === "TWEET_ENGAGEMENTS") {
    return <BidDetailTweetEngagements index={index} disabled={disabled} />
  } else if (field.value === "PROMOTED_VIDEO_VIEWS") {
    return <BidDetailTPromotedVideoViews index={index} disabled={disabled} />
  } else if (field.value === "AWARENESS") {
    return <BidDetailAwareness index={index} disabled={disabled} />
  } else if (field.value === "WEBSITE_CLICKS") {
    return <BidDetailWebsiteClicks index={index} disabled={disabled} />
  } else if (field.value === "IN_STREAM_VIDEO_AD_VIEWS_PREROLL") {
    return <BidDetailInstreamVideoAdViews index={index} disabled={disabled} />
  } else if (field.value === "FOLLOWERS") {
    return <BidDetailFollowers index={index} disabled={disabled} />
  } else if (field.value === "APP_INSTALLS") {
    return <BidDetailAppInstalls index={index} disabled={disabled} />
  } else if (field.value === "APP_ENGAGEMENTS") {
    return <BidDetailAppEngagements index={index} disabled={disabled} />
  } else {
    return null
  }
}
