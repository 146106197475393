/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Button, Select, TableCell, TableRow } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { useFormikContext } from "formik"
import React, { useState } from "react"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"
import { CampaignObjective as CampaignObjectiveValue } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  children?: never
}
/**
 * キャンペーン情報入力エリア
 */
export const CampaignObjective: React.FC<OwnProps> = () => {
  const { values, setFieldValue } = useFormikContext<CommonFormValues>()

  const [obj, setObj] = useState<CampaignObjectiveValue | "">(
    values.campaign.campaignObjective
  )

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            キャンペーンの目的
          </TableCell>
          <TableCell align="left" css={selectTableTh}>
            <Select
              value={obj}
              onChange={(e) => {
                const obj = e.target.value as CampaignObjectiveValue
                setObj(obj)
              }}
              displayEmpty
              css={selectTh}
            >
              <MenuItem value="" disabled>
                選択してください
              </MenuItem>
              <MenuItem value="TWEET_ENGAGEMENTS">
                ツイートのエンゲージメント
              </MenuItem>
              <MenuItem value="PROMOTED_VIDEO_VIEWS">
                プロモビデオ再生数
              </MenuItem>
              <MenuItem value="AWARENESS">ブランド認知度の向上</MenuItem>
              <MenuItem value="WEBSITE_CLICKS">
                ウェブサイトへの誘導数またはコンバージョン
              </MenuItem>
              <MenuItem value="IN_STREAM_VIDEO_AD_VIEWS_PREROLL">
                インストリーム動画再生数(プレロール)
              </MenuItem>
              <MenuItem value="FOLLOWERS">フォロワー</MenuItem>
              <MenuItem value="APP_INSTALLS">アプリのインストール数</MenuItem>
              <MenuItem value="APP_ENGAGEMENTS">アプリの起動回数</MenuItem>
            </Select>
            <Button
              color="primary"
              variant="contained"
              css={button}
              onClick={async () => {
                if (obj === "AWARENESS") {
                  // キャンペーンの目的を選択する際は新規作成の場合に限り、
                  // 広告グループ(配列)は必ず1つしかないため以下のように指定した
                  setFieldValue(`adGroups.${0}.others.bidPricingUnit`, "CPM")
                  setFieldValue(`adGroups.${0}.others.chargeBy`, "IMPRESSION")
                }
                await setFieldValue("campaign.campaignObjective", obj)
              }}
              disabled={obj === ""}
            >
              選択した目的で新規作成
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const tableTh = css`
  width: 240px;
  border: none;
`

const selectTableTh = css`
  border: none;
`

const selectTh = css`
  width: 240px;
`

const button = css`
  margin-left: 60px;
`
