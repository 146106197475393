/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { TextField } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import { useFormikContext } from "formik"
import React, { Fragment } from "react"
import {
  displayErrorMsgBidAmount,
  displayErrorMsgBidType,
} from "src/components/helpers/FormUtils/utils"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { NumberFieldRow } from "src/components/molecules/NumberFieldRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

/**
 * キャンペーンの目的「アプリのインストール数」を選択した時の入札タイプ、詳細用コンポーネント
 */
export const BidDetailAppInstalls: React.FC<OwnProps> = ({
  index,
  disabled,
}) => {
  const { setFieldValue, values, errors } = useFormikContext<CommonFormValues>()
  const { others, optimizationPreference } = values.adGroups[index]

  return (
    <Fragment>
      <MultipleFieldRow label="入札タイプ">
        <TextField
          value={others.bidType}
          name={`adGroups.${index}.others.bidType`}
          onChange={(event) => {
            setFieldValue(
              `adGroups.${index}.others.bidType`,
              event.target.value
            )
            setFieldValue(`adGroups.${index}.others.bidAmount`, 0)
          }}
          size="small"
          variant="outlined"
          select
          css={inlineSelect}
          disabled={disabled}
          error={!!displayErrorMsgBidType(errors, index)}
          helperText={displayErrorMsgBidType(errors, index)}
        >
          <MenuItem value="AUTO_BID">自動入札</MenuItem>
          <MenuItem value="AUTO_BID_WITH_TARGET">目標コスト</MenuItem>
          <MenuItem value="MAX_BID">上限入札単価</MenuItem>
        </TextField>
      </MultipleFieldRow>
      {(values.adGroups[index].others.bidType === "AUTO_BID_WITH_TARGET" ||
        values.adGroups[index].others.bidType === "MAX_BID") && (
        <NumberFieldRow
          label="入札額"
          initialValue={others.bidAmount}
          onSetValue={(value) =>
            setFieldValue(`adGroups.${index}.others.bidAmount`, value)
          }
          fieldName={`adGroups.${index}.others.bidAmount`}
          disabled={disabled}
          errorMsg={displayErrorMsgBidAmount(errors, index)}
        />
      )}
      <TextFieldRow
        label="入札価格単位"
        initialValue={others.bidPricingUnit}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.bidPricingUnit`, value)
        }
        fieldName={`adGroups.${index}.others.bidPricingUnit`}
        select
        disabled={disabled}
      >
        <MenuItem value="-">アプリクリックあたり</MenuItem>
        <MenuItem value="COST_PER_INSTALL">インストールあたり</MenuItem>
      </TextFieldRow>
      <TextFieldRow
        label="最適化設定"
        initialValue={optimizationPreference}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.optimizationPreference`, value)
        }
        fieldName={`adGroups.${index}.optimizationPreference`}
        select
        disabled={disabled}
      >
        <MenuItem value="NONE">アプリのクリック数</MenuItem>
        <MenuItem value="CONVERSIONS">インストール</MenuItem>
      </TextFieldRow>
      <TextFieldRow
        label="請求方法"
        initialValue={others.chargeBy}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.chargeBy`, value)
        }
        fieldName={`adGroups.${index}.others.chargeBy`}
        select
        disabled={disabled}
      >
        <MenuItem value="-">アプリのクリック</MenuItem>
        <MenuItem value="INSTALL">インストール</MenuItem>
      </TextFieldRow>
    </Fragment>
  )
}

const inlineSelect = css`
  min-width: 240px;
  margin-right: 16px;
`
