/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import RadioGroup from "@material-ui/core/RadioGroup"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React from "react"

type OwnProps = {
  disabled?: boolean
  errorMsg?: string
  fieldName: string
  label: string
  onSetValue: (value: string | undefined) => void
  value: string
}

/**
 * ラベルと入力エリアを表示する、テーブル1行分のコンポーネント
 */
export const RadioFieldRow: React.FC<OwnProps> = ({
  label,
  value,
  fieldName,
  children,
  errorMsg,
  onSetValue,
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" css={tableTh}>
        {label}
      </TableCell>
      <TableCell align="left">
        <RadioGroup
          onChange={(event) => {
            onSetValue(event.target.value)
          }}
          name={fieldName}
          value={value}
          row
        >
          {children}
        </RadioGroup>
        {!!errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      </TableCell>
    </TableRow>
  )
}
const tableTh = css`
  width: 240px;
`
