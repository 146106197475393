import {
  CampaignId,
  InhouseCampaignId,
} from "src/domainLayer/models/CampaignInfo"
import { AccountId } from "src/domainLayer/models/TwitterAPI"
import { Brand, CastAny, ValueOf } from "src/types/Utils"

/**
 * react-router用のパスの定義
 */
const _RoutePath = {
  ROOT: "/",
  // 入稿依頼新規作成画面
  CREATE_ORDER: "/orders/create",
  // 継続新規作成画面
  CREATE_ORDER_WITH_CONTINUE: "/orders/create/:accountId/:campaignId",
  // 入稿依頼詳細
  ORDER: "/orders/:inhouseCampaignId",
  NOT_FOUND: "/not_found",
  // 開発用SQL発行画面
  CONNECT_DB_DEV: "/connect-db",
  PUBLISHED_CAMPAIGNS: "/campaigns",
} as const

type RoutePathKey = keyof typeof _RoutePath

/**
 * react-router用のパス定義の型
 */
export type RoutePathValue = Brand<ValueOf<typeof _RoutePath>, "RoutePathValue">

/**
 * react-router用のパスの定義
 */
export const RoutePath: Record<
  RoutePathKey,
  RoutePathValue
> = _RoutePath as CastAny

/**
 * 動的なパスの定義
 */
export const DynamicRoutePath = {
  ORDER: (inhouseCampaignId: InhouseCampaignId) => {
    return RoutePath.ORDER.replace(
      ":inhouseCampaignId",
      inhouseCampaignId.toString()
    )
  },
  CREATE_ORDER_WITH_CONTINUE: (
    campaignId: CampaignId,
    accountId: AccountId
  ) => {
    return `${RoutePath.CREATE_ORDER}?accountId=${accountId}&campaignId=${campaignId}`
  },
} as const

export type DynamicRouteParams = {
  EDIT_ORDER: {
    inhouseCampaignId: InhouseCampaignId
  }
  ORDER: {
    inhouseCampaignId: InhouseCampaignId
  }
}
