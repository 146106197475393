import { UserInfo } from "src/domainLayer/models/UserInfo"
import actionCreatorFactory from "typescript-fsa"

const DOMAIN = "UserInfo/"

export const ActionTypes = {
  FETCH_USER_INFO: DOMAIN + "FETCH_USER_INFO",
  FETCH_ACCOUNT_LIST: DOMAIN + "FETCH_ACCOUNT_LIST",
}

const create = actionCreatorFactory()

/**
 * ユーザー情報を取得
 */
export const fetchUserInfo = create.async<void, UserInfo, void>(
  ActionTypes.FETCH_USER_INFO
)
