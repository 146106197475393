/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { ExpansionPanel, ExpansionPanelSummary } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React, { Fragment } from "react"
import { AdGroupConfirmArea } from "src/components/molecules/AdGroupConfirmArea"
import { ClientCampaignConfirmArea } from "src/components/molecules/ClientCampaignConfirmArea"
import { CampaignDetail } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  campaignDetail: CampaignDetail
  children?: never
}

export const ConfirmArea: React.FC<OwnProps> = ({ campaignDetail }) => {
  const { campaign, adGroups } = campaignDetail

  return (
    <Fragment>
      <h3>クライアント/キャンペーン</h3>
      <ClientCampaignConfirmArea campaignInfo={campaign} />

      <h3>広告グループ</h3>
      {adGroups.map((a, index) => {
        return (
          <Paper key={a.adGroupName}>
            <ExpansionPanel defaultExpanded elevation={0} css={adsGroup}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>広告グループ {index + 1}</Typography>
              </ExpansionPanelSummary>
              <AdGroupConfirmArea
                adInfo={a}
                campaignObjective={campaign.campaignObjective}
              />
            </ExpansionPanel>
          </Paper>
        )
      })}
    </Fragment>
  )
}

const adsGroup = css`
  margin-bottom: 30px;
  word-break: break-all;
  &:before {
    display: none;
  }
`
