import {
  CampaignDetail,
  CampaignSummary,
  InhouseCampaignId,
  PublishedCampaignDetail,
} from "src/domainLayer/models/CampaignInfo"
import actionCreatorFactory from "typescript-fsa"

const DOMAIN = "Campaign/"

export const ActionTypes = {
  FETCH_CAMPAIGN_LIST: DOMAIN + "FETCH_CAMPAIGN_LIST",
  FETCH_CAMPAIGN_DETAIL: DOMAIN + "FETCH_CAMPAIGN_DETAIL",
  FETCH_PUBLISHED_CAMPAIGN_DETAIL: DOMAIN + "FETCH_PUBLISHED_CAMPAIGN_DETAIL",
  POST_CAMPAIGN_DETAIL: DOMAIN + "POST_CAMPAIGN_DETAIL",
  UPDATE_CAMPAIGN_DETAIL: DOMAIN + "UPDATE_CAMPAIGN_DETAIL",
  CREATE_RELATED_AD_GROUPS: DOMAIN + "CREATE_RELATED_AD_GROUPS",
  UPDATE_RELATED_AD_GROUPS: DOMAIN + "UPDATE_RELATED_AD_GROUPS",
  UPDATE_STATUS: DOMAIN + "UPDATE_STATUS",
  DELETE_CAMPAIGN: DOMAIN + "DELETE_CAMPAIGN",
  DELETE_AD_GROUP: DOMAIN + "DELETE_AD_GROUP",
  REFRESH_CAMPAIGN_DETAIL: DOMAIN + "REFRESH_CAMPAIGN_DETAIL",
}

const create = actionCreatorFactory()

/**
 * キャンペーン一覧を取得する
 */
export const fetchCampaignList = create.async<void, CampaignSummary[], void>(
  ActionTypes.FETCH_CAMPAIGN_LIST
)

/**
 * キャンペーン詳細を取得する
 */
export const fetchCampaignDetail = create.async<void, CampaignDetail, void>(
  ActionTypes.FETCH_CAMPAIGN_DETAIL
)

/**
 * 継続しているキャンペーン詳細を取得する
 */
export const fetchPublishedCampaignDetail = create.async<
  void,
  PublishedCampaignDetail,
  void
>(ActionTypes.FETCH_PUBLISHED_CAMPAIGN_DETAIL)

/**
 * キャンペーンの新規作成
 */
export const postCampaign = create.async<void, InhouseCampaignId, void>(
  ActionTypes.POST_CAMPAIGN_DETAIL
)

/**
 * キャンペーンの更新
 */
export const updateCampaignDetail = create.async<void, void, void>(
  ActionTypes.UPDATE_CAMPAIGN_DETAIL
)

/**
 * キャンペーンの一部として広告グループを新規作成
 */
export const createRelatedAdGroups = create.async<void, void, void>(
  ActionTypes.CREATE_RELATED_AD_GROUPS
)

/**
 * キャンペーンの一部である広告グループの更新
 */
export const updateRelatedAdGroups = create.async<void, void, void>(
  ActionTypes.UPDATE_RELATED_AD_GROUPS
)

/**
 * キャンペーン・広告のステータスの更新
 */
export const updateStatus = create.async<void, void, void>(
  ActionTypes.UPDATE_STATUS
)

/**
 * キャンペーン単体の削除
 */
export const deleteCampaign = create.async<void, void, void>(
  ActionTypes.DELETE_CAMPAIGN
)

/**
 * 広告単体の削除
 */
export const deleteAdGroup = create.async<void, void, void>(
  ActionTypes.DELETE_AD_GROUP
)

/**
 * 継続情報を全て削除する
 */
export const refreshCampaignDetail = create(ActionTypes.REFRESH_CAMPAIGN_DETAIL)
