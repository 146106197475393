import addDays from "date-fns/addDays"
import endOfDay from "date-fns/endOfDay"
import startOfDay from "date-fns/startOfDay"
import {
  BaseAdGroupValues,
  BaseCampaignValues,
} from "src/components/helpers/FormUtils/types/CommonFormValues"
import { getNow } from "src/utils/DateTimeUtils"

/**
 * 条件に依らない、共通の入力項目
 * 新規・継続新規作成時の入力項目
 */
export type CommonFormValues = {
  adGroups: BaseAdGroupValues[]
  campaign: BaseCampaignValues
}

export const localInitialValues: CommonFormValues = {
  campaign: {
    consultation: "not_implementation",
    delivery: "standard_delivery",
    campaignObjective: "",
    // キャンペーン開始日の初期値を、今日から3日後とする
    campaignStartDate: startOfDay(addDays(getNow(), 3)),
    // キャンペーン終了日の初期値を、今日から3日後とする
    campaignEndDate: endOfDay(addDays(getNow(), 3)),
    campaignStatus: "DRAFT",
    campaignDailyBudget: 0,
    campaignName: "",
    campaignTotalBudget: 0,
    clientName: "",
    fundingSourceId: "",
    grossBudget: 0,
    twitterAccountName: "",
  },
  adGroups: [
    {
      optimizationPreference: "NONE",
      adGroupPlacementValues: {
        PROFILES: false,
        SEARCH_RESULTS: false,
        TIMELINES: true,
      },
      adGroupEndTime: endOfDay(addDays(getNow(), 3)),
      adGroupName: "",
      adGroupStartTime: startOfDay(addDays(getNow(), 3)),
      adGroupTotalBudget: 0,
      promotedProductType: "",
      others: {
        gender: "ANY_GENDER",
        age: "",
        language: "ja",
        location: "i35:Japan",
        requestExpandBroadMatchKeywords: false,
        bidType: "AUTO_BID",
        bidAmount: 0,
        chargeBy: "-",
        bidPricingUnit: "-",
        deviceActivationDuration: "",
        isTailoredAudienceLookalike: false,
      },
    },
  ],
}
