import { CircularProgress } from "@material-ui/core"
import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withMyAuthenticator } from "src/components/helpers/withMyAuthenticator"
import { settingsOperations } from "src/ducks/settings"
import { RootState } from "src/ducks/store"
import { twitterAPIOperations } from "src/ducks/twitterAPI"

type OwnProps = {
  // NOP
}

/**
 * 初期描画時に必要なデータを取得し、Reduxに格納する
 * アプリの初期データとしてロードしたい処理をまとめるコンポーネント
 */
const _Initialize: React.FC<OwnProps> = ({ children }) => {
  const dispatch = useDispatch()
  const isLoadingUserInfo = useSelector(
    (state: RootState) => state.settings.isLoadingUserInfo
  )

  useEffect(() => {
    dispatch(settingsOperations.fetchUserInfo())
    // アカウント一覧を取得
    dispatch(twitterAPIOperations.fetchAccountList())
  }, [dispatch])

  if (isLoadingUserInfo) {
    return <CircularProgress />
  }
  return <Fragment>{children}</Fragment>
}

export const Initialize = withMyAuthenticator(_Initialize)
