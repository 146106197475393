import axios from "axios"
import { INHOUSE_ADS_API_PATH } from "src/constants/Env"
import { FetchAccountListResp } from "src/dataLayer/apis/TwitterAPI/types/FetchAccountListResp"
import { FetchCampaignListResp } from "src/dataLayer/apis/TwitterAPI/types/FetchCampaignListResp"
import { AuthorizationToken } from "src/dataLayer/apis/types"
import { AccountId } from "src/domainLayer/models/TwitterAPI"

/**
 * Twitter API に紐付くデータ（アカウント情報、実行中のキャンペーン等）に関する API
 */

/**
 * （特別な理由がなければ）共通で使う URL パラメータ
 */
export type UrlParams = {
  account_id: AccountId
}

/**
 * TwitterAPIからアカウント一覧を取得
 * GET /accounts
 * @param authToken 認証トークン
 * @param urlParams URL中のパラメータ
 * @param queryStrings GETパラメータ
 * @param requestBody POSTパラメータ
 */
export const fetchAccountList = async (
  authToken: AuthorizationToken
): Promise<FetchAccountListResp> => {
  const resp = await axios.get<FetchAccountListResp>(
    `${INHOUSE_ADS_API_PATH}/accounts`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}
/**
 * TwitterAPIからキャンペーン一覧を取得
 * GET /accounts/{account_id}/campaigns
 */
export const fetchCampaignList = async (
  authToken: AuthorizationToken,
  UrlParams: UrlParams
): Promise<FetchCampaignListResp> => {
  const resp = await axios.get<FetchCampaignListResp>(
    `${INHOUSE_ADS_API_PATH}/accounts/${UrlParams.account_id}/campaigns`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}
