import * as operations from "./operations"
import { State, reducers } from "./reducers"
import * as selectors from "./selectors"

/**
 * Twitterから取得したデータ(アカウント、キャンペーン)に関する state
 */

export const twitterAPIOperations = operations
export const twitterAPIReducer = reducers
export const twitterAPISelectors = selectors

export type twitterAPIState = State
