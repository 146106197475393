import "react-table/react-table.css"

import React from "react"
import { Link } from "react-router-dom"
import ReactTable, { CellInfo, Column } from "react-table"
import { DynamicRoutePath } from "src/constants/Paths"
import { PublishedCampaign } from "src/domainLayer/models/TwitterAPI"

type OwnProps = {
  children?: never
  data: PublishedCampaign[]
}

const Operation: React.FC<CellInfo> = (row) => {
  const o = row.original as PublishedCampaign

  return (
    <Link
      to={DynamicRoutePath.CREATE_ORDER_WITH_CONTINUE(
        o.campaignId,
        o.accountId
      )}
    >
      新規作成
    </Link>
  )
}

const convertToCampaignColumns = (): Array<Column<PublishedCampaign>> => {
  return [
    {
      Header: "キャンペーン名",
      accessor: "campaignName",
    },
    {
      Header: "開始日時",
      accessor: "campaignStartDate",
    },
    {
      Header: "終了日時",
      accessor: "campaignEndDate",
    },
    {
      Header: "キャンペーン予算(ネット)",
      accessor: "campaignTotalBudget",
    },
    {
      Header: "操作",
      accessor: "",
      Cell: Operation,
    },
  ]
}

export const CampaignTable: React.FC<OwnProps> = ({ data }) => {
  return (
    <ReactTable
      data={data}
      columns={convertToCampaignColumns()}
      defaultPageSize={30}
      className="-striped -highlight"
    />
  )
}
