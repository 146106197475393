import "react-table/react-table.css"

import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import ReactTable, { CellInfo, Column } from "react-table"
import { DynamicRoutePath } from "src/constants/Paths"
import { CampaignSummary } from "src/domainLayer/models/CampaignInfo"
import { settingsSelectors } from "src/ducks/settings"

type OwnProps = {
  children?: never
  data: CampaignSummary[]
}

const CampaignStatus: React.FC<CellInfo> = (row) => {
  const o = row.original as CampaignSummary
  const role = useSelector(settingsSelectors.getUserRoleStrict)

  if (role === "sales") {
    if (o.inhouseOrderStatus === 2 && !o.inhouseOrderSendBackFlag) {
      return <div>下書き</div>
    } else if (o.inhouseOrderStatus === 2 && o.inhouseOrderSendBackFlag) {
      return <div>差し戻し</div>
    } else if (o.inhouseOrderStatus === 3) {
      return <div>依頼中</div>
    } else if (o.inhouseOrderStatus === 4) {
      return <div>受領済み</div>
    } else if (o.inhouseOrderStatus === 5) {
      return <div>完了</div>
    } else {
      return <div>不明 {o.inhouseOrderStatus}</div>
    }
  } else {
    if (o.inhouseOrderStatus === 2 && !o.inhouseOrderSendBackFlag) {
      return <div>依頼前</div>
    } else if (o.inhouseOrderStatus === 2 && o.inhouseOrderSendBackFlag) {
      return <div>差し戻し中</div>
    } else if (o.inhouseOrderStatus === 3) {
      return <div>新規依頼</div>
    } else if (o.inhouseOrderStatus === 4) {
      return <div>下書き</div>
    } else if (o.inhouseOrderStatus === 5) {
      return <div>完了</div>
    } else {
      return <div>不明 {o.inhouseOrderStatus}</div>
    }
  }
}

const CampaignName: React.FC<CellInfo> = (row) => {
  const o = row.original as CampaignSummary

  // 空文字表示すると、リンクが表示されなくなるため
  let campaignName = o.campaignName
  if (campaignName == null || campaignName.trim().length === 0) {
    campaignName = "(未設定)"
  }

  return (
    <Link to={DynamicRoutePath.ORDER(o.inhouseCampaignId)}>{campaignName}</Link>
  )
}

const convertToCampaignColumns = (): Array<Column<CampaignSummary>> => {
  return [
    {
      Header: "編集状態",
      accessor: "",
      Cell: CampaignStatus,
    },
    {
      Header: "クライアント名",
      accessor: "clientName",
      filterable: true,
    },
    {
      Header: "キャンペーン名",
      accessor: "campaignName",
      filterable: true,
      style: { margin: "auto" },
      Cell: CampaignName,
    },
    {
      Header: "開始日時",
      accessor: "campaignStartDate",
    },
    {
      Header: "終了日時",
      accessor: "campaignEndDate",
    },
    {
      Header: "キャンペーン予算(ネット)",
      accessor: "campaignTotalBudget",
    },
    {
      Header: "登録者",
      accessor: "inhouseOrderCreatedBy",
    },
    {
      Header: "登録日",
      accessor: "inhouseOrderCreatedAt",
    },
    {
      Header: "更新者",
      accessor: "inhouseOrderUpdatedBy",
    },
    {
      Header: "更新日",
      accessor: "inhouseOrderUpdatedAt",
    },
  ]
}

export const CampaignTable: React.FC<OwnProps> = ({ data }) => {
  return (
    <ReactTable
      data={data}
      columns={convertToCampaignColumns()}
      defaultPageSize={30}
      className="-striped -highlight"
    />
  )
}
