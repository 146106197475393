import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CampaignTable } from "src/components/pages/Root/CampaignTable"
import { campaignOperations } from "src/ducks/campaign"
import { RootState } from "src/ducks/store"

type OwnProps = {
  children?: never
}

export const Root: React.FC<OwnProps> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // キャンペーン一覧取得
    dispatch(campaignOperations.fetchCampaignList())
  }, [dispatch])

  const data = useSelector((state: RootState) => state.campaign.list)
  return (
    <Fragment>
      <h2>キャンペーン 一覧</h2>
      <CampaignTable data={data} />
    </Fragment>
  )
}
