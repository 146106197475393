/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React from "react"
import { FastTextField } from "src/components/atoms/FastTextField"

type OwnProps = {
  disabled?: boolean
  errorMsg?: string
  fieldName: string
  initialValue: string | undefined
  label: string
  onSetValue: (value: string | undefined) => void
  placeholder?: string
  select?: boolean
}

/**
 * ラベルと入力エリアを表示する、テーブル1行分のコンポーネント
 */
export const TextFieldRow: React.FC<OwnProps> = ({
  label,
  initialValue,
  onSetValue,
  fieldName,
  placeholder,
  children,
  select,
  disabled,
  errorMsg,
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" css={tableTh}>
        {label}
      </TableCell>
      <TableCell align="left">
        <FastTextField
          initialValue={initialValue}
          onSetValue={onSetValue}
          name={fieldName}
          fullWidth
          variant="outlined"
          size="small"
          placeholder={placeholder}
          select={select}
          disabled={disabled}
          error={!!errorMsg}
          helperText={errorMsg}
        >
          {children}
        </FastTextField>
      </TableCell>
    </TableRow>
  )
}
const tableTh = css`
  width: 240px;
`
