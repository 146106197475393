/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import Select, { ValueType } from "react-select"
import { CampaignTable } from "src/components/pages/PublishedCampaigns/CampaignTable"
import { useFetchPublishedCampaignList } from "src/components/pages/PublishedCampaigns/useFetchPublishedCampaignList"
import { AccountId, PublishedCampaign } from "src/domainLayer/models/TwitterAPI"
import { RootState } from "src/ducks/store"

type OwnProps = {
  children?: never
}

type OptionType = {
  label: string
  value: AccountId
}

export const PublishedCampaigns: React.FC<OwnProps> = () => {
  const { fetchPublishedCampaignList } = useFetchPublishedCampaignList()

  const [valueList, setValueList] = useState<PublishedCampaign[]>([])

  const accountList = useSelector(
    (state: RootState) => state.twitterAPI.accountList
  )

  const isLoadingAccountList = useSelector(
    (state: RootState) => state.twitterAPI.isLoadingAccountList
  )

  const accountOptions: OptionType[] = accountList.map((account) => ({
    value: account.id,
    label: account.name,
  }))

  const onChange = async (
    accountValue: ValueType<OptionType>
  ): Promise<void> => {
    if (accountValue == null) {
      return
    }
    const selectedAccountId = (accountValue as OptionType).value
    const list = await fetchPublishedCampaignList({
      accountId: selectedAccountId,
    })
    setValueList(list)
  }

  return (
    <Fragment>
      <h2>実行中のキャンペーン 一覧</h2>
      <Select
        isSearchable
        options={accountOptions}
        isDisabled={isLoadingAccountList}
        css={select}
        placeholder="アカウントを選択してください"
        onChange={onChange}
      />
      <CampaignTable data={valueList} />
    </Fragment>
  )
}
const select = css`
  margin-bottom: 16px;
`
