import { InhouseUserRole } from "src/domainLayer/models/UserInfo"
import { RootState } from "src/ducks/store"

/**
 * @throws UserRole 未取得の場合、例外
 */
export const getUserRoleStrict = (rootState: RootState): InhouseUserRole => {
  const state = rootState.settings

  if (state.userInfo == null) {
    throw new Error("`state.userInfo` is null. Fetch userInfo first.")
  }
  return state.userInfo.userRole
}
