import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import React from "react"
import { AdGroupDetail } from "src/components/organisms/AdsGroupInfoForm/AdGroupDetail"
import { CreativeDetail } from "src/components/organisms/AdsGroupInfoForm/CreativeDetail"
import { LookalikeExpansionSetting } from "src/components/organisms/AdsGroupInfoForm/LookalikeExpansionSetting"
import { Retargeting } from "src/components/organisms/AdsGroupInfoForm/Retargeting"
import { TailoredAudience } from "src/components/organisms/AdsGroupInfoForm/TailoredAudience"
import { TargetingKeywords } from "src/components/organisms/AdsGroupInfoForm/TargetingKeywords"
import { TargetingUserDetail } from "src/components/organisms/AdsGroupInfoForm/TargetingUserDetail"
import { UserDeviceDetail } from "src/components/organisms/AdsGroupInfoForm/UserDeviceDetail"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

/**
 * 入稿依頼編集用の広告グループ情報入力エリア
 */
export const AdsGroupInfoForm: React.FC<OwnProps> = ({ index, disabled }) => {
  return (
    <Table>
      <TableBody>
        <AdGroupDetail index={index} disabled={disabled} />
        <TailoredAudience index={index} disabled={disabled} />
        <TargetingUserDetail index={index} disabled={disabled} />
        <UserDeviceDetail index={index} disabled={disabled} />
        <TargetingKeywords index={index} disabled={disabled} />
        <Retargeting index={index} disabled={disabled} />
        <LookalikeExpansionSetting index={index} disabled={disabled} />
        <CreativeDetail index={index} disabled={disabled} />
      </TableBody>
    </Table>
  )
}
