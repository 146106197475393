/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React from "react"

type OwnProps = {
  label: string
}

/**
 * 複数入力項目をラップする、テーブル1行用コンポーネント
 */
export const MultipleFieldRow: React.FC<OwnProps> = ({ label, children }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" css={tableTh}>
        {label}
      </TableCell>
      <TableCell align="left">{children}</TableCell>
    </TableRow>
  )
}
const tableTh = css`
  width: 240px;
`
