import * as CognitoAPI from "src/dataLayer/apis/CognitoAPI"
import * as TwitterAPI from "src/dataLayer/apis/TwitterAPI"
import { AccountId } from "src/domainLayer/models/TwitterAPI"
import { AppThunkAction } from "src/types/ReduxTypes"

import * as actions from "./actions"

/**
 * TwitterAPIからアカウント情報を取得
 */
export const fetchAccountList = (): AppThunkAction<void> => {
  return async (dispatch) => {
    dispatch(actions.fetchAccountList.started())

    let result
    try {
      result = await TwitterAPI.fetchAccountList(
        await CognitoAPI.fetchOrGetIdToken()
      )
    } catch (error) {
      console.error(error)
      actions.fetchAccountList.failed({ error: undefined })
      return
    }
    dispatch(actions.fetchAccountList.done({ result: result.data }))
  }
}

/**
 * TwitterAPIからキャンペーン情報を取得
 */
export const fetchPublishedCampaignList = (
  accountId: AccountId
): AppThunkAction<void> => {
  return async (dispatch) => {
    dispatch(actions.fetchPublishedCampaignList.started({ accountId }))

    let result
    try {
      result = await TwitterAPI.fetchCampaignList(
        await CognitoAPI.fetchOrGetIdToken(),
        { account_id: accountId }
      )
    } catch (error) {
      console.error(error)
      actions.fetchPublishedCampaignList.failed({
        params: { accountId },
        error: undefined,
      })
      return
    }
    dispatch(
      actions.fetchPublishedCampaignList.done({
        result: result.data,
        params: { accountId },
      })
    )
  }
}
