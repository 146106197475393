import {
  AdGroup,
  CommonFormValues,
} from "src/components/helpers/FormUtils/types/CommonFormValues"
import {
  convertDraftAdGroupCommonForm,
  convertDraftCampaignAndClientCommonForm,
} from "src/components/helpers/FormUtils/utils"
import * as AdGroupAPI from "src/dataLayer/apis/AdGroupAPI"
import { PostAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PostAdGroupsReq"
import { PutAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PutAdGroupsReq"
import { PutCampaignReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignReq"
import { InhouseAdGroupStatus } from "src/domainLayer/models/AdsGroupInfo"
import { InhouseCampaignId } from "src/domainLayer/models/CampaignInfo"

export const convertCampaignReqByOperator = (
  v: CommonFormValues
): PutCampaignReq => {
  return {
    campaign: {
      ...convertDraftCampaignAndClientCommonForm(v),
      inhouseCampaignStatus: 4,
      publishedFlag: false,
      sendBackFlag: false,
    },
  }
}

export const convertPostAdGroupReqByOperator = (
  target: AdGroup,
  parentId: InhouseCampaignId
): Omit<AdGroupAPI.UrlParams, "inhouse_ad_group_id"> & PostAdGroupsReq => {
  return {
    inhouse_campaign_id: parentId,
    adGroups: [
      {
        ...convertDraftAdGroupCommonForm(target),
        inhouseAdGroupStatus: 4 as InhouseAdGroupStatus,
        publishedFlag: false,
        sendBackFlag: false,
      },
    ],
  }
}

export const convertPutAdGroupReqByOperator = (
  target: AdGroup,
  parentId: InhouseCampaignId
): AdGroupAPI.UrlParams & PutAdGroupsReq => {
  return {
    inhouse_campaign_id: parentId,
    inhouse_ad_group_id: target.inhouseAdGroupId,
    adGroups: [
      {
        ...convertDraftAdGroupCommonForm(target),
        inhouseAdGroupStatus: 4 as InhouseAdGroupStatus,
        publishedFlag: false,
        sendBackFlag: false,
      },
    ],
  }
}
