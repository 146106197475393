export const isProd = process.env.REACT_APP_ENV_NAME === "production"
export const isLocal = process.env.REACT_APP_ENV_NAME === "local"

/**
 * Cognito > ユーザープール > 全般設定 > アプリクライアント > アプリクライアント ID
 */
export const COGNITO_APP_CLIENT_ID = process.env
  .REACT_APP_COGNITO_APP_CLIENT_ID!

/**
 * Cognito > フェデレーティッドアイデンティティ > サンプルコード > ID プールの ID
 */
export const COGNITO_IDENTITY_POOL_ID = process.env
  .REACT_APP_COGNITO_IDENTITY_POOL_ID!

export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION!

/**
 * Cognito > ユーザープール > 全般設定 > プール ID
 */
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID!

export const INHOUSE_ADS_API_PATH = process.env.REACT_APP_INHOUSE_ADS_API_PATH!
