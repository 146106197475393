import produce from "immer"
import { Action } from "redux"
import { UserInfo } from "src/domainLayer/models/UserInfo"
import { isType } from "typescript-fsa"

import * as actions from "./actions"

export type State = Readonly<{
  isLoadingUserInfo: boolean
  userInfo: UserInfo | undefined
}>

const initialState: State = {
  isLoadingUserInfo: true,
  userInfo: undefined,
}

export const reducers = (state = initialState, action: Action): State => {
  if (isType(action, actions.fetchUserInfo.started)) {
    return produce(state, (draft) => {
      draft.isLoadingUserInfo = true
    })
  }
  if (isType(action, actions.fetchUserInfo.done)) {
    return produce(state, (draft) => {
      const { result } = action.payload
      draft.userInfo = result
      draft.isLoadingUserInfo = false
    })
  }
  if (isType(action, actions.fetchUserInfo.failed)) {
    return produce(state, () => {
      // const { error } = action.payload
      // TODO エラーハンドリング
      console.error("ユーザー情報の取得に失敗")
    })
  }
  return state
}
