/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import { useFormikContext } from "formik"
import React from "react"
import { FastTextField } from "src/components/atoms/FastTextField"
import { AccordionPanelRow } from "src/components/molecules/AccordionPanelRow"
import { LabeledCheckbox } from "src/components/molecules/LabeledCheckbox"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const TailoredAudience: React.FC<OwnProps> = ({ index, disabled }) => {
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()

  return (
    <MultipleFieldRow label="オーディエンス">
      <LabeledCheckbox
        label="選択したオーディエンスに似ているアカウントをターゲティングします。"
        checked={values.adGroups[index].others.isTailoredAudienceLookalike}
        onSetValue={(value) => {
          setFieldValue(
            `adGroups.${index}.others.isTailoredAudienceLookalike`,
            value
          )
        }}
        disabled={disabled}
      />
      <FastTextField
        label="オーディエンス"
        initialValue={values.adGroups[index].others.commonTailoredAudienceList}
        onSetValue={(value) =>
          setFieldValue(
            `adGroups.${index}.others.commonTailoredAudienceList`,
            value
          )
        }
        fullWidth
        variant="outlined"
        size="small"
        css={input}
        name={`adGroups.${index}.others.commonTailoredAudienceList`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
      />
      <Table>
        <TableBody>
          <AccordionPanelRow label="その他のテイラードオーディエンス">
            <TableRow>
              <TableCell colSpan={2} css={accordionCell}>
                <FastTextField
                  label="テイラードオーディエンス (モバイルアプリから)"
                  initialValue={
                    values.adGroups[index].others
                      .commonTailoredAudiencesFromMobileApps
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.commonTailoredAudiencesFromMobileApps`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.commonTailoredAudiencesFromMobileApps`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
                <FastTextField
                  label="テイラードオーディエンスのウェブサイト訪問者"
                  initialValue={
                    values.adGroups[index].others
                      .commonTailoredAudiencesWebsiteVisitors
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.commonTailoredAudiencesWebsiteVisitors`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.commonTailoredAudiencesWebsiteVisitors`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
                <FastTextField
                  label="柔軟なオーディエンス"
                  initialValue={
                    values.adGroups[index].others.commonFlexibleAudiences
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.commonFlexibleAudiences`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.commonFlexibleAudiences`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
              </TableCell>
            </TableRow>
          </AccordionPanelRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <AccordionPanelRow label="除外するテイラードオーディエンス">
            <TableRow>
              <TableCell colSpan={2} css={accordionCell}>
                <FastTextField
                  label="除外するオーディエンス"
                  initialValue={
                    values.adGroups[index].others.tailoredAudienceListExcluded
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.tailoredAudienceListExcluded`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.tailoredAudienceListExcluded`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
                <FastTextField
                  label="除外するテイラードオーディエンス (モバイルアプリから)"
                  initialValue={
                    values.adGroups[index].others
                      .tailoredAudiencesFromMobileAppsExcluded
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.tailoredAudiencesFromMobileAppsExcluded`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.tailoredAudiencesFromMobileAppsExcluded`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
                <FastTextField
                  label="除外するテイラードオーディエンスのウェブサイト訪問者"
                  initialValue={
                    values.adGroups[index].others
                      .tailoredAudiencesWebsiteVisitorsExcluded
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.tailoredAudiencesWebsiteVisitorsExcluded`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.tailoredAudiencesWebsiteVisitorsExcluded`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
                <FastTextField
                  label="除外する柔軟なオーディエンス"
                  initialValue={
                    values.adGroups[index].others.flexibleAudiencesExcluded
                  }
                  onSetValue={(value) =>
                    setFieldValue(
                      `adGroups.${index}.others.flexibleAudiencesExcluded`,
                      value
                    )
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  css={input}
                  name={`adGroups.${index}.others.flexibleAudiencesExcluded`}
                  placeholder="セミコロン( ; )区切りで入力してください"
                  disabled={disabled}
                />
              </TableCell>
            </TableRow>
          </AccordionPanelRow>
        </TableBody>
      </Table>
    </MultipleFieldRow>
  )
}
const input = css`
  margin-top: 8px;
`
const accordionCell = css`
  padding: 0 0 24px 0;
`
