/** @jsx jsx */
import DateFnsUtils from "@date-io/date-fns"
import { css, jsx } from "@emotion/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { FieldInputProps } from "formik"
import React from "react"
import { DATE_TIME_FORMAT_FOR_API } from "src/utils/DateTimeUtils"

type OwnProps = {
  disabled?: boolean
  errorMsg?: string
  field: FieldInputProps<Date | null>
  label: string
  onSetValue: (value: Date | null) => void
}

/**
 * 日付と時間を選択させるコンポーネント
 */
export const DateTimePickerRow: React.FC<OwnProps> = ({
  label,
  field,
  onSetValue,
  disabled,
  errorMsg,
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" css={tableTh}>
        {label}
      </TableCell>
      <TableCell align="left">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            name={field.name}
            clearable
            format={DATE_TIME_FORMAT_FOR_API}
            // undefined 非対応らしいため (null にしないと空表示にならない)
            value={field.value == null ? null : field.value}
            size="small"
            inputVariant="outlined"
            onChange={(value) => {
              onSetValue(value)
            }}
            disabled={disabled}
            error={!!errorMsg}
            helperText={errorMsg}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
    </TableRow>
  )
}
const tableTh = css`
  width: 240px;
`
