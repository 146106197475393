import { Brand } from "src/types/Utils"
/**
 * このアプリケーションにログインしたユーザーの情報関連
 */

export type UserInfo = {
  userRole: InhouseUserRole
}

/**
 * Cognitoで管理しているID
 */
export type CognitoUserId = Brand<number, "CognitoUserId">

/**
 * 社内用役割別ID
 * sales: 営業(新規入稿から入稿依頼まで)
 * operator: 運用(入稿依頼を差し戻す、場合によっては入稿依頼内容を追加編集し、実際にバルクシートに起こすまで)
 */
export type InhouseUserRole = "sales" | "operator"

export const InhouseUserRoleValues: readonly InhouseUserRole[] = [
  "sales",
  "operator",
]
