import Amplify from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom"
import { Provider as ReduxProvider } from "react-redux"
import { Initialize } from "src/components/helpers/Initialize"
import { Routes } from "src/components/helpers/Routes"
import { GlobalStyles } from "src/components/styles/GlobalStyles"
import {
  COGNITO_APP_CLIENT_ID,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
} from "src/constants/Env"
import { configureStore } from "src/ducks/store"

import { unregister } from "./serviceWorker"

const reduxStore = configureStore()

Amplify.configure({
  Auth: {
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_APP_CLIENT_ID,
  },
})

const App: React.FC = () => {
  return (
    <ReduxProvider store={reduxStore}>
      <GlobalStyles />
      <Initialize>
        <Routes />
      </Initialize>
    </ReduxProvider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()
