/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Fab } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Formik } from "formik"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { OrderDetailMetaInfo } from "src/components/atoms/OrderDetailMetaInfo"
import { localInitialValues } from "src/components/helpers/FormUtils/types/CommonFormValues"
import { convertAPIToForm } from "src/components/helpers/FormUtils/utils"
import { ClientCampaignInfo } from "src/components/pages/EditOrder/ClientCampaignInfo"
import { EditAdsGroupsInfo } from "src/components/pages/EditOrder/EditAdsGroupInfo"
import { commonValidationSchema } from "src/components/pages/EditOrder/form/validations/commonValidationSchema"
import { RoutePath } from "src/constants/Paths"
import { assertIsInhouseAdGroupId } from "src/domainLayer/models/AdsGroupInfo"
import { CampaignDetail } from "src/domainLayer/models/CampaignInfo"
import { campaignOperations } from "src/ducks/campaign"

type OwnProps = {
  campaignDetail: CampaignDetail
  children?: never
}

export const EditOrder: React.FC<OwnProps> = ({ campaignDetail }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const deleteCampaign = (): void => {
    const isOk = window.confirm("本当に下書きを削除してよろしいですか?")
    if (!isOk) {
      return
    }

    dispatch(
      campaignOperations.deleteCampaign(
        campaignDetail.campaign.inhouseCampaignId,
        () => history.push(RoutePath.ROOT)
      )
    )
  }

  const initialValues = useMemo(() => convertAPIToForm(campaignDetail), [
    campaignDetail,
  ])
  const {
    inhouseOrderCreatedBy,
    inhouseOrderCreatedAt,
    inhouseOrderUpdatedBy,
    inhouseOrderUpdatedAt,
  } = campaignDetail.campaign

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || localInitialValues}
      validationSchema={commonValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        await actions.setFieldValue("submitAsComplete", true)
        const errors = await actions.validateForm()
        actions.setFieldValue("submitAsComplete", false)

        if (Object.keys(errors).length) {
          return
        }

        await dispatch(
          campaignOperations.updateStatus(
            {
              inhouse_campaign_id: values.campaign.inhouseCampaignId,
              adGroups: values.adGroups.map((a) => {
                assertIsInhouseAdGroupId(a.inhouseAdGroupId)
                return {
                  id: a.inhouseAdGroupId,
                  sendBackFlag: a.sendBackFlag,
                  status:
                    a.inhouseAdGroupStatus <= 2 ? 3 : a.inhouseAdGroupStatus,
                }
              }),
              campaign: {
                id: values.campaign.inhouseCampaignId,
                sendBackFlag: values.campaign.sendBackFlag,
                status:
                  values.campaign.inhouseCampaignStatus <= 2
                    ? 3
                    : values.campaign.inhouseCampaignStatus,
              },
            },
            () => {
              history.push(RoutePath.ROOT)
            }
          )
        )

        actions.setSubmitting(false)
      }}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" component="h1">
            入稿依頼編集画面
          </Typography>
          <OrderDetailMetaInfo
            inhouseOrderCreatedBy={inhouseOrderCreatedBy}
            inhouseOrderCreatedAt={inhouseOrderCreatedAt}
            inhouseOrderUpdatedBy={inhouseOrderUpdatedBy}
            inhouseOrderUpdatedAt={inhouseOrderUpdatedAt}
          />
          <h3>クライアント/キャンペーン情報</h3>
          <ClientCampaignInfo />
          <h3>広告グループ</h3>
          <EditAdsGroupsInfo />
          <div css={buttonGroup}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                deleteCampaign()
              }}
            >
              下書きを削除
            </Button>
            <Fab
              color="primary"
              variant="extended"
              type="submit"
              disabled={
                values.campaign.isEdit ||
                values.adGroups.some((ag) => ag.isEdit === true)
              }
              css={receivedButton}
            >
              運用に依頼
            </Fab>
          </div>
        </form>
      )}
    </Formik>
  )
}

const buttonGroup = css`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`

const receivedButton = css`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
`
