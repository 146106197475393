/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { Fragment } from "react"
import { Route, HashRouter as Router, Switch } from "react-router-dom"
import { Sidebar } from "src/components/organisms/Sidebar"
import { ConnectDB } from "src/components/pages/ConnectDB"
import { CreateOrder } from "src/components/pages/CreateOrder"
import { Order } from "src/components/pages/Order"
import { PublishedCampaigns } from "src/components/pages/PublishedCampaigns"
import { Root } from "src/components/pages/Root"
import { RoutePath } from "src/constants/Paths"

type Props = {
  children?: never
}

export const Routes: React.FC<Props> = () => {
  return (
    <Fragment>
      <div css={wrapper}>
        <Router>
          <nav css={sidebar}>
            <Sidebar />
          </nav>
          <main css={content}>
            <Switch>
              <Route exact path={RoutePath.ROOT} component={Root} />
              <Route path={RoutePath.CREATE_ORDER} component={CreateOrder} />
              <Route path={RoutePath.ORDER} component={Order} />
              <Route
                exact
                path={RoutePath.PUBLISHED_CAMPAIGNS}
                component={PublishedCampaigns}
              />
              <Route
                exact
                path={RoutePath.CONNECT_DB_DEV}
                component={ConnectDB}
              />
            </Switch>
          </main>
        </Router>
      </div>
    </Fragment>
  )
}

const wrapper = css`
  display: flex;
`
const sidebar = css`
  width: 280px;
`
const content = css`
  flex: 1;
  padding: 32px;
`
