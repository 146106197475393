import {
  AdGroupId,
  AdGroupServingStatus,
  BidPricingUnit,
  BidType,
  ChargeBy,
  InhouseAdGroupId,
  InhouseAdGroupStatus,
  OptimizationPreference,
  RetargetingEngagementType,
  TweetType,
} from "src/domainLayer/models/AdsGroupInfo"
import {
  AdGroupPlacement,
  Age,
  CampaignObjective,
  Consultation,
  Delivery,
  DeviceActivationDuration,
  Gender,
  InhouseCampaignId,
  InhouseCampaignStatus,
  LookalikeExpansionSetting,
} from "src/domainLayer/models/CampaignInfo"
import { getNow } from "src/utils/DateTimeUtils"

/**
 * 条件に依らない、共通の入力項目
 */
export type CommonFormValues = {
  adGroups: AdGroup[]
  campaign: Campaign
  /**
   * 「完了」の場合のみバリデーションを実行するための値
   * ユーザーが何のアクションを実行するか事前に決めることができないため、ボタンのクリックイベント時に変更する
   */
  submitAsComplete: boolean
}

export type Campaign = {
  campaignAppId?: string
  campaignDailyBudget: number
  campaignEndDate: Date
  campaignFrequencyCap?: string
  campaignFrequencyCapTimeDuration?: string
  campaignName: string
  campaignObjective: CampaignObjective
  campaignStartDate: Date
  campaignStatus: string
  campaignTotalBudget: number
  clientName: string
  consultation: Consultation
  delivery: Delivery
  fundingSourceId?: string
  grossBudget: number
  inhouseCampaignId: InhouseCampaignId
  inhouseCampaignStatus: InhouseCampaignStatus
  inhouseOrderCreatedAt: string
  inhouseOrderCreatedBy: string
  inhouseOrderUpdatedAt: string | undefined
  inhouseOrderUpdatedBy: string | undefined
  isEdit: boolean
  publishedFlag: boolean
  sendBackFlag: boolean
  twitterAccountName: string
}

export const DefaultAdGroupPlacementValues: Readonly<Record<
  AdGroupPlacement,
  boolean
>> = {
  PROFILES: false,
  SEARCH_RESULTS: false,
  TIMELINES: false,
}

export type AdGroup = {
  adGroupEndTime?: Date
  adGroupId?: AdGroupId
  adGroupName: string
  adGroupPlacementValues: Record<AdGroupPlacement, boolean>
  adGroupServingStatus?: AdGroupServingStatus
  adGroupStartTime?: Date
  adGroupTotalBudget?: number
  inhouseAdGroupId: InhouseAdGroupId
  inhouseAdGroupStatus: InhouseAdGroupStatus
  isEdit: boolean
  optimizationPreference: OptimizationPreference
  others: Others
  promotedProductType?: string
  publishedFlag: boolean
  sendBackFlag: boolean
}

export type Others = {
  age: Age | ""
  bidAmount: number
  bidPricingUnit: BidPricingUnit
  bidType: BidType
  broadMatchKeywords?: string
  campaignRetargeting?: string
  chargeBy: ChargeBy
  commonFlexibleAudiences?: string
  commonTailoredAudienceList?: string
  commonTailoredAudiencesFromMobileApps?: string
  commonTailoredAudiencesWebsiteVisitors?: string
  conversations?: string
  deviceActivationDuration: DeviceActivationDuration | ""
  eventTargetingIds?: string
  flexibleAudiencesExcluded?: string
  followerTargetingLookalikes?: string
  gender: Gender
  isTailoredAudienceLookalike: boolean
  language: string
  location: string
  lookalikeExpansionSetting?: LookalikeExpansionSetting
  mediaCreativeIds?: string
  mobileCarriers?: string
  phraseKeywordsExcluded?: string
  platform?: string
  requestExpandBroadMatchKeywords: boolean
  retargetingEngagementType?: RetargetingEngagementType
  tailoredAudienceListExcluded?: string
  tailoredAudiencesFromMobileAppsExcluded?: string
  tailoredAudiencesWebsiteVisitorsExcluded?: string
  trackingWithDoubleclick?: string
  tvShows?: string
  tweetIds: string
  tweetType?: TweetType
  userDevice?: string
  userInterests?: string
  userOsVersion?: string
}

/**
 * API から取得する前の初期値
 */
export const localInitialValues: CommonFormValues = {
  adGroups: [
    {
      inhouseAdGroupId: 0 as InhouseAdGroupId,
      adGroupPlacementValues: DefaultAdGroupPlacementValues,
      adGroupName: "",
      optimizationPreference: "NONE",
      others: {
        requestExpandBroadMatchKeywords: false,
        age: "",
        deviceActivationDuration: "",
        gender: "ANY_GENDER",
        bidType: "AUTO_BID",
        bidAmount: 0,
        bidPricingUnit: "-",
        chargeBy: "-",
        language: "",
        location: "",
        tweetIds: "",
        isTailoredAudienceLookalike: false,
      },
      isEdit: false,
      inhouseAdGroupStatus: 1,
      publishedFlag: false,
      sendBackFlag: false,
    },
  ],
  campaign: {
    campaignStatus: "DRAFT",
    campaignEndDate: getNow(),
    campaignObjective: "FOLLOWERS",
    campaignStartDate: getNow(),
    consultation: "not_implementation",
    delivery: "standard_delivery",
    inhouseCampaignId: 0 as InhouseCampaignId,
    isEdit: false,
    clientName: "",
    twitterAccountName: "",
    grossBudget: 0,
    campaignName: "",
    campaignDailyBudget: 0,
    campaignTotalBudget: 0,
    inhouseCampaignStatus: 1,
    publishedFlag: false,
    sendBackFlag: false,
    inhouseOrderCreatedAt: "",
    inhouseOrderCreatedBy: "",
    inhouseOrderUpdatedAt: undefined,
    inhouseOrderUpdatedBy: undefined,
  },
  submitAsComplete: false,
}
