import produce from "immer"
import { Action } from "redux"
import {
  CampaignDetail,
  CampaignSummary,
  PublishedCampaignDetail,
} from "src/domainLayer/models/CampaignInfo"
import { isType } from "typescript-fsa"

import * as actions from "./actions"

export type State = Readonly<{
  detail: CampaignDetail | undefined
  list: CampaignSummary[]
  publishedDetail: PublishedCampaignDetail | undefined
}>

const initialState: State = {
  list: [],
  detail: undefined,
  publishedDetail: undefined,
}

export const reducers = (state = initialState, action: Action): State => {
  /**
   * キャンペーン一覧を取得
   */
  if (isType(action, actions.fetchCampaignList.done)) {
    return produce(state, (draft) => {
      const { result } = action.payload
      draft.list = result
    })
  }

  /**
   * fetch
   */
  if (isType(action, actions.fetchCampaignDetail.started)) {
    return produce(state, (draft) => {
      draft.detail = undefined
    })
  }
  if (isType(action, actions.fetchCampaignDetail.done)) {
    return produce(state, (draft) => {
      const { result } = action.payload
      draft.detail = result
    })
  }

  if (isType(action, actions.fetchPublishedCampaignDetail.started)) {
    return produce(state, (draft) => {
      draft.publishedDetail = undefined
    })
  }
  if (isType(action, actions.fetchPublishedCampaignDetail.done)) {
    return produce(state, (draft) => {
      const { result } = action.payload
      draft.publishedDetail = result
    })
  }

  /**
   * 継続キャンペーンのリフレッシュ
   */
  if (isType(action, actions.refreshCampaignDetail)) {
    return produce(state, (draft) => {
      draft.publishedDetail = undefined
    })
  }

  return state
}
