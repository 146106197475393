/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Button from "@material-ui/core/Button"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import React, { Fragment } from "react"

type OwnProps = {
  children?: never
}

export const ConnectDB: React.FC<OwnProps> = () => {
  return (
    <Fragment>
      <h2>DB接続エリア</h2>
      <h3>クエリ実行</h3>
      <div>
        <TextareaAutosize
          rows={10}
          placeholder="SELECT * FROM TABLE;"
          css={textArea}
        />
      </div>
      <div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          css={submitButton}
        >
          実行
        </Button>
      </div>
      <br />
      <h3>実行結果</h3>
      <div css={resultArea}></div>
    </Fragment>
  )
}

const textArea = css`
  width: 600px;
  font-size: 1.5rem;
`

const submitButton = css`
  margin: auto;
`

const resultArea = css`
  width: 600px;
  font-size: 1.5rem;
  min-height: 300px;
  background-color: white;
  border: solid 0.5px gray;
`
