import MenuItem from "@material-ui/core/MenuItem"
import { useFormikContext } from "formik"
import React from "react"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const LookalikeExpansionSetting: React.FC<OwnProps> = ({
  index,
  disabled,
}) => {
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()

  return (
    <TextFieldRow
      label="オーディエンスを広げる"
      initialValue={
        values.adGroups[index].others.lookalikeExpansionSetting != null
          ? values.adGroups[index].others.lookalikeExpansionSetting
          : ""
      }
      onSetValue={(value) =>
        setFieldValue(
          `adGroups.${index}.others.lookalikeExpansionSetting`,
          value
        )
      }
      fieldName={`adGroups.${index}.others.lookalikeExpansionSetting`}
      select
      disabled={disabled}
    >
      <MenuItem value="">選択してください</MenuItem>
      <MenuItem value="DEFINED">DEFINED</MenuItem>
      <MenuItem value="EXPANDED">EXPANDED</MenuItem>
      <MenuItem value="BROAD">BROAD</MenuItem>
    </TextFieldRow>
  )
}
