/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { AdGroupInner } from "src/domainLayer/models/AdsGroupInfo"

type OwnProps = {
  adInfo: AdGroupInner
  children?: never
}

export const TailoredAudience: React.FC<OwnProps> = ({ adInfo }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            オーディエンス
          </TableCell>
          <TableCell align="left">
            {adInfo.others.tailoredAudienceList?.join(",")}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            除外するオーディエンス
          </TableCell>
          <TableCell align="left">
            {adInfo.others.tailoredAudienceListExcluded?.join(",")}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} css={accordionCell}>
            <ExpansionPanel elevation={0} css={accordionCellPanel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                css={accordionCellTitle}
              >
                その他のテイラードオーディエンス
              </ExpansionPanelSummary>
              <ExpansionPanelDetails css={accordionCellContent}>
                <Table>
                  <TableBody>
                    {adInfo.others.tailoredAudiencesFromMobileApps != null &&
                      adInfo.others.tailoredAudiencesFromMobileApps.length !==
                        0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            テイラードオーディエンス (モバイルアプリから)
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudiencesFromMobileApps?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.tailoredAudiencesFromMobileAppsExcluded !=
                      null &&
                      adInfo.others.tailoredAudiencesFromMobileAppsExcluded
                        .length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            除外するテイラードオーディエンス
                            (モバイルアプリから)
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudiencesFromMobileAppsExcluded?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.tailoredAudiencesWebsiteVisitors != null &&
                      adInfo.others.tailoredAudiencesWebsiteVisitors.length !==
                        0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            テイラードオーディエンスのウェブサイト訪問者
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudiencesWebsiteVisitors?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.tailoredAudiencesWebsiteVisitorsExcluded !=
                      null &&
                      adInfo.others.tailoredAudiencesWebsiteVisitorsExcluded
                        .length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            除外するテイラードオーディエンスのウェブサイト訪問者
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudiencesWebsiteVisitorsExcluded?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.flexibleAudiences != null &&
                      adInfo.others.flexibleAudiences.length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            柔軟なオーディエンス
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.flexibleAudiences?.join(",")}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.flexibleAudiencesExcluded != null &&
                      adInfo.others.flexibleAudiencesExcluded.length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            除外する柔軟なオーディエンス
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.flexibleAudiencesExcluded?.join(",")}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.tailoredAudiencesFromMobileAppsLookalikes !=
                      null &&
                      adInfo.others.tailoredAudiencesFromMobileAppsLookalikes
                        .length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            テイラードオーディエンス (モバイルアプリから)
                            の類似オーディエンス
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudiencesFromMobileAppsLookalikes?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.tailoredAudiencesWebsiteVisitorsLookalikes !=
                      null &&
                      adInfo.others.tailoredAudiencesWebsiteVisitorsLookalikes
                        .length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            テイラードオーディエンスのウェブサイト訪問者の類似オーディエンス
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudiencesWebsiteVisitorsLookalikes?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.tailoredAudienceLookalike != null &&
                      adInfo.others.tailoredAudienceLookalike.length !== 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            テイラードオーディエンスの類似オーディエンス
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.tailoredAudienceLookalike?.join(",")}
                          </TableCell>
                        </TableRow>
                      )}
                    {adInfo.others.flexibleAudiencesLookalikes != null &&
                      adInfo.others.flexibleAudiencesLookalikes.length !==
                        0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" css={tableTh}>
                            柔軟なオーディエンスの類似オーディエンス
                          </TableCell>
                          <TableCell align="left">
                            {adInfo.others.flexibleAudiencesLookalikes?.join(
                              ","
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const accordionCell = css`
  padding: 0;
`
const accordionCellTitle = css`
  padding-left: 16px;
`
const tableTh = css`
  width: 240px;
`
const accordionCellPanel = css`
  border-radius: 0;
  &:before {
    display: none;
  }
`
const accordionCellContent = css`
  padding: 0 0 0 40px;
`
