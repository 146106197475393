import { CircularProgress } from "@material-ui/core"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Confirm } from "src/components/pages/Confirm"
import { EditOrder } from "src/components/pages/EditOrder"
import { EditRequestOrderDetail } from "src/components/pages/EditRequestOrderDetail"
import { ReadinessPublish } from "src/components/pages/ReadinessPublish"
import { assertAndConvertInhouseCampaignId } from "src/domainLayer/models/CampaignInfo"
import { campaignOperations } from "src/ducks/campaign"
import { RootState } from "src/ducks/store"

type OwnProps = {
  children?: never
}

export const Order: React.FC<OwnProps> = () => {
  const { inhouseCampaignId: _inhouseCampaignId } = useParams()
  const inhouseCampaignId = assertAndConvertInhouseCampaignId(
    _inhouseCampaignId
  )
  const dispatch = useDispatch()
  const campaignDetail = useSelector(
    (state: RootState) => state.campaign.detail
  )

  const orderStatus = useSelector(
    (state: RootState) => state.campaign.detail?.campaign.inhouseOrderStatus
  )

  useEffect(() => {
    // 登録済みのデータを取得
    dispatch(campaignOperations.fetchCampaignDetail(inhouseCampaignId))
  }, [dispatch, inhouseCampaignId])

  if (campaignDetail == null) {
    return <CircularProgress />
  }

  if (!campaignDetail.campaign.inhouseEditable) {
    return <Confirm campaignDetail={campaignDetail} />
  }

  switch (orderStatus) {
    case 2:
      return <EditOrder campaignDetail={campaignDetail} />
    case 3:
    case 4:
      return <EditRequestOrderDetail campaignDetail={campaignDetail} />
    case 5:
      return <ReadinessPublish campaignDetail={campaignDetail} />
    default:
      // 直接リンクで対応するためにはAPIの仕様を変更する必要がある
      return (
        <div>直接リンクエラー: TOPページからフローに沿って運用してください</div>
      )
  }
}
