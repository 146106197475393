import { getIn } from "formik"
import { FormikErrors } from "node_modules/formik/dist/types"
import {
  AdGroup,
  BaseAdGroupValues,
  BaseCampaignValues,
  CommonFormValues,
  DefaultAdGroupPlacementValues,
} from "src/components/helpers/FormUtils/types/CommonFormValues"
import { CommonFormValues as CreateOrderCommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"
import { AdGroup as putAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PutAdGroupsReq"
import { CommonCampaignParams } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignReq"
import {
  BaseAdGroup,
  RetargetingEngagementType,
  RetargetingType,
} from "src/domainLayer/models/AdsGroupInfo"
import {
  AdGroupPlacement,
  BaseCampaign,
  CampaignDetail,
  CampaignId,
  PublishedCampaignDetail,
  assertIsAdGroupPlacement,
} from "src/domainLayer/models/CampaignInfo"
import { formatDateAsAPI, toDateFromAPI } from "src/utils/DateTimeUtils"
import {
  emptyValueToUndefined,
  semicolonStringToArray,
  undefinedToEmptyValue,
} from "src/utils/StringUtils"

/**
 * この form 固有の utils
 */
export const convertAPIToForm = (data: CampaignDetail): CommonFormValues => {
  const c = data.campaign
  return {
    adGroups: data.adGroups.map((a) => {
      return {
        ...convertCommonAdGroupAPIToForm(a),
        inhouseAdGroupId: a.inhouseAdGroupId,
        inhouseAdGroupStatus: a.inhouseAdGroupStatus,
        publishedFlag: a.publishedFlag,
        sendBackFlag: a.sendBackFlag,
      }
    }),
    campaign: {
      ...convertCommonCampaignAPIToForm(c),
      inhouseCampaignId: data.campaign.inhouseCampaignId,
      inhouseCampaignStatus: data.campaign.inhouseCampaignStatus,
      publishedFlag: data.campaign.publishedFlag,
      sendBackFlag: data.campaign.sendBackFlag,
    },
    submitAsComplete: false,
  }
}

/**
 * 継続新規作成の場合の form 固有の utils
 */
// 新規作成の場合しか使わないため返り値を CreateOrderCommonFormValues としたい
export const convertPublishedAPIToForm = (
  data: PublishedCampaignDetail
): CreateOrderCommonFormValues => {
  const c = data.campaign
  return {
    adGroups: data.adGroups.map((a) => {
      return convertCommonAdGroupAPIToForm(a)
    }),
    campaign: convertCommonCampaignAPIToForm(c),
  }
}

export const convertCommonAdGroupAPIToForm = (
  a: BaseAdGroup
): BaseAdGroupValues => {
  const isTailoredAudienceLookalike =
    (!!a.others.tailoredAudienceLookalike &&
      a.others.tailoredAudienceLookalike.length > 0) ||
    (!!a.others.tailoredAudiencesFromMobileAppsLookalikes &&
      a.others.tailoredAudiencesFromMobileAppsLookalikes.length > 0) ||
    (!!a.others.tailoredAudiencesWebsiteVisitorsLookalikes &&
      a.others.tailoredAudiencesWebsiteVisitorsLookalikes.length > 0) ||
    (!!a.others.flexibleAudiencesLookalikes &&
      a.others.flexibleAudiencesLookalikes.length > 0)
  return {
    adGroupEndTime:
      a.adGroupEndTime == null || a.adGroupEndTime === ""
        ? undefined
        : toDateFromAPI(a.adGroupEndTime),
    adGroupId: a.adGroupId,
    adGroupName: a.adGroupName,
    adGroupPlacementValues: a.adGroupPlacementValues.reduce((acc, curr) => {
      // キーが存在するものだけ true で上書き（＝セットされてる値のフラグを立てる）
      return {
        ...acc,
        [curr]: true,
      }
    }, DefaultAdGroupPlacementValues),
    adGroupServingStatus: a.adGroupServingStatus,
    adGroupStartTime:
      a.adGroupStartTime == null || a.adGroupStartTime === ""
        ? undefined
        : toDateFromAPI(a.adGroupStartTime),
    adGroupTotalBudget: a.adGroupTotalBudget,
    optimizationPreference: a.optimizationPreference,
    others: {
      age: undefinedToEmptyValue(a.others.age),
      bidAmount: a.others.bidAmount,
      bidPricingUnit: a.others.bidPricingUnit,
      bidType: a.others.bidType,
      broadMatchKeywords: a.others.broadMatchKeywords?.join(";") || "",
      campaignRetargeting: a.others.campaignRetargeting?.join(";") || "",
      chargeBy: a.others.chargeBy,
      deviceActivationDuration: a.others.deviceActivationDuration || "",
      eventTargetingIds: a.others.eventTargetingIds?.join(";") || "",
      followerTargetingLookalikes:
        a.others.followerTargetingLookalikes?.join(";") || "",
      gender: a.others.gender,
      language: a.others.language.join(";"),
      location: a.others.location.join(";"),
      lookalikeExpansionSetting: a.others.lookalikeExpansionSetting,
      mediaCreativeIds: a.others.mediaCreativeIds?.join(";") || "",
      mobileCarriers: a.others.mobileCarriers?.join(";") || "",
      platform: a.others.platform?.join(";") || "",
      requestExpandBroadMatchKeywords: a.others.requestExpandBroadMatchKeywords,
      retargetingType: decideRetargetingType(
        a.others.retargetingEngagementType
      ),
      retargetingEngagementType: a.others.retargetingEngagementType,
      commonTailoredAudienceList: isTailoredAudienceLookalike
        ? a.others.tailoredAudienceLookalike?.join(";") || ""
        : a.others.tailoredAudienceList?.join(";") || "",
      commonTailoredAudiencesFromMobileApps: isTailoredAudienceLookalike
        ? a.others.tailoredAudiencesFromMobileAppsLookalikes?.join(";") || ""
        : a.others.tailoredAudiencesFromMobileApps?.join(";") || "",
      commonTailoredAudiencesWebsiteVisitors: isTailoredAudienceLookalike
        ? a.others.tailoredAudiencesWebsiteVisitorsLookalikes?.join(";") || ""
        : a.others.tailoredAudiencesWebsiteVisitors?.join(";") || "",
      commonFlexibleAudiences: isTailoredAudienceLookalike
        ? a.others.flexibleAudiencesLookalikes?.join(";") || ""
        : a.others.flexibleAudiences?.join(";") || "",
      tailoredAudienceListExcluded:
        a.others.tailoredAudienceListExcluded?.join(";") || "",
      tailoredAudiencesFromMobileAppsExcluded:
        a.others.tailoredAudiencesFromMobileAppsExcluded?.join(";") || "",
      tailoredAudiencesWebsiteVisitorsExcluded:
        a.others.tailoredAudiencesWebsiteVisitorsExcluded?.join(";") || "",
      flexibleAudiencesExcluded:
        a.others.flexibleAudiencesExcluded?.join(";") || "",
      isTailoredAudienceLookalike: isTailoredAudienceLookalike,
      tvShows: a.others.tvShows?.join(";") || "",
      conversations: a.others.conversations?.join(";") || "",
      tweetIds: a.others.tweetIds?.join(";") || "",
      tweetType: a.others.tweetType,
      phraseKeywordsExcluded: a.others.phraseKeywordsExcluded?.join(";") || "",
      userDevice: a.others.userDevice?.join(";") || "",
      userInterests: a.others.userInterests?.join(";") || "",
      userOsVersion: a.others.userOsVersion?.join(";") || "",
    },
    promotedProductType: a.promotedProductType,
    isEdit: false,
  }
}

export const convertCommonCampaignAPIToForm = (
  c: BaseCampaign
): BaseCampaignValues => {
  return {
    campaignDailyBudget: c.campaignDailyBudget,
    campaignAppId: c.campaignAppId?.join(";") || "",
    campaignEndDate:
      c.campaignEndDate == null || c.campaignEndDate === ""
        ? undefined
        : toDateFromAPI(c.campaignEndDate),
    campaignFrequencyCap: c.campaignFrequencyCap,
    campaignFrequencyCapTimeDuration: c.campaignFrequencyCapTimeDuration,
    campaignName: c.campaignName,
    campaignObjective: undefinedToEmptyValue(c.campaignObjective),
    campaignStartDate:
      c.campaignStartDate == null || c.campaignStartDate === ""
        ? undefined
        : toDateFromAPI(c.campaignStartDate),
    campaignStatus: c.campaignStatus,
    campaignTotalBudget: c.campaignTotalBudget,
    clientName: c.clientName,
    consultation: c.consultation ? "implementation" : "not_implementation",
    delivery: c.delivery ? "standard_delivery" : "centralized_delivery",
    grossBudget: c.grossBudget,
    twitterAccountName: c.twitterAccountName,
    fundingSourceId: c.fundingSourceId,
    isEdit: false,
    inhouseOrderCreatedAt: c.inhouseOrderCreatedAt,
    inhouseOrderCreatedBy: c.inhouseOrderCreatedBy,
    inhouseOrderUpdatedAt: c.inhouseOrderUpdatedAt,
    inhouseOrderUpdatedBy: c.inhouseOrderUpdatedBy,
  }
}

/**
 * 下書き form (キャンペーン・クライアント情報) からAPIに送るためにバックエンド用の値に変換する処理
 * 共通パラメータ
 */
export const convertDraftCampaignAndClientCommonForm = (
  v: CommonFormValues
): CommonCampaignParams => {
  return {
    campaignDailyBudget: v.campaign.campaignDailyBudget,
    campaignEndDate: formatDateAsAPI(v.campaign.campaignEndDate),
    campaignFrequencyCap: v.campaign.campaignFrequencyCap,
    campaignFrequencyCapTimeDuration:
      v.campaign.campaignFrequencyCapTimeDuration,
    // 下書き保存のため、必ずこの値
    campaignId: "" as CampaignId,
    campaignName: v.campaign.campaignName,
    campaignObjective: emptyValueToUndefined(v.campaign.campaignObjective),
    campaignAppId: semicolonStringToArray(v.campaign.campaignAppId),
    campaignStartDate: formatDateAsAPI(v.campaign.campaignStartDate),
    campaignStatus: v.campaign.campaignStatus,
    campaignTotalBudget: v.campaign.campaignTotalBudget,
    clientName: v.campaign.clientName,
    consultation: v.campaign.consultation === "implementation",
    delivery: v.campaign.delivery === "standard_delivery",
    fundingSourceId: v.campaign.fundingSourceId,
    grossBudget: v.campaign.grossBudget,
    twitterAccountName: v.campaign.twitterAccountName,
  }
}

/**
 * 下書き form (広告グループ情報) からAPIに送るためにバックエンド用の値に変換する処理
 * 共通パラメータ
 */
export const convertDraftAdGroupCommonForm = (
  v: AdGroup
): Omit<
  putAdGroupsReq,
  "inhouseAdGroupStatus" | "publishedFlag" | "sendBackFlag"
> => {
  return {
    adGroupEndTime: formatDateAsAPI(v.adGroupEndTime),
    adGroupId: v.adGroupId,
    adGroupName: v.adGroupName,
    adGroupPlacement: Object.entries(v.adGroupPlacementValues)
      .filter((v) => v[1])
      .reduce((accum, curr) => {
        assertIsAdGroupPlacement(curr[0])
        accum.push(curr[0])
        return accum
      }, [] as AdGroupPlacement[])
      .join(","),
    adGroupServingStatus: v.adGroupServingStatus,
    adGroupStartTime: formatDateAsAPI(v.adGroupStartTime),
    adGroupTotalBudget: v.adGroupTotalBudget,
    optimizationPreference: v.optimizationPreference,
    others: {
      age: emptyValueToUndefined(v.others.age),
      bidAmount: v.others.bidAmount,
      bidType: v.others.bidType,
      broadMatchKeywords: semicolonStringToArray(v.others.broadMatchKeywords),
      mediaCreativeIds: semicolonStringToArray(v.others.mediaCreativeIds),
      deviceActivationDuration: emptyValueToUndefined(
        v.others.deviceActivationDuration
      ),
      eventTargetingIds: semicolonStringToArray(v.others.eventTargetingIds),
      followerTargetingLookalikes: semicolonStringToArray(
        v.others.followerTargetingLookalikes
      ),
      gender: v.others.gender,
      language: semicolonStringToArray(v.others.language),
      location: semicolonStringToArray(v.others.location),
      lookalikeExpansionSetting: v.others.lookalikeExpansionSetting,
      mobileCarriers: semicolonStringToArray(v.others.mobileCarriers),
      platform: semicolonStringToArray(v.others.platform),
      tailoredAudienceList: v.others.isTailoredAudienceLookalike
        ? []
        : semicolonStringToArray(v.others.commonTailoredAudienceList),
      tailoredAudienceLookalike: v.others.isTailoredAudienceLookalike
        ? semicolonStringToArray(v.others.commonTailoredAudienceList)
        : [],
      tailoredAudiencesFromMobileApps: v.others.isTailoredAudienceLookalike
        ? []
        : semicolonStringToArray(
            v.others.commonTailoredAudiencesFromMobileApps
          ),
      tailoredAudiencesFromMobileAppsLookalikes: v.others
        .isTailoredAudienceLookalike
        ? semicolonStringToArray(v.others.commonTailoredAudiencesFromMobileApps)
        : [],
      tailoredAudiencesWebsiteVisitors: v.others.isTailoredAudienceLookalike
        ? []
        : semicolonStringToArray(
            v.others.commonTailoredAudiencesWebsiteVisitors
          ),
      tailoredAudiencesWebsiteVisitorsLookalikes: v.others
        .isTailoredAudienceLookalike
        ? semicolonStringToArray(
            v.others.commonTailoredAudiencesWebsiteVisitors
          )
        : [],
      flexibleAudiences: v.others.isTailoredAudienceLookalike
        ? []
        : semicolonStringToArray(v.others.commonFlexibleAudiences),
      flexibleAudiencesLookalikes: v.others.isTailoredAudienceLookalike
        ? semicolonStringToArray(v.others.commonFlexibleAudiences)
        : [],
      tailoredAudienceListExcluded: semicolonStringToArray(
        v.others.tailoredAudienceListExcluded
      ),
      tailoredAudiencesFromMobileAppsExcluded: semicolonStringToArray(
        v.others.tailoredAudiencesFromMobileAppsExcluded
      ),
      tailoredAudiencesWebsiteVisitorsExcluded: semicolonStringToArray(
        v.others.tailoredAudiencesWebsiteVisitorsExcluded
      ),
      flexibleAudiencesExcluded: semicolonStringToArray(
        v.others.flexibleAudiencesExcluded
      ),
      trackingWithDoubleclick: v.others.trackingWithDoubleclick,
      tvShows: semicolonStringToArray(v.others.tvShows),
      conversations: semicolonStringToArray(v.others.conversations),
      tweetIds: semicolonStringToArray(v.others.tweetIds),
      phraseKeywordsExcluded: semicolonStringToArray(
        v.others.phraseKeywordsExcluded
      ),
      userDevice: semicolonStringToArray(v.others.userDevice),
      userInterests: semicolonStringToArray(v.others.userInterests),
      userOsVersion: semicolonStringToArray(v.others.userOsVersion),
      campaignRetargeting:
        v.others.tweetType === "Organic"
          ? []
          : // オーガニックツイートの時はキャンペーンが指定出来ないため、
            // 操作の末に入ってしまった値を間違えて保存しないために空にする
            semicolonStringToArray(v.others.campaignRetargeting),
      retargetingEngagementType: emptyValueToUndefined(
        v.others.retargetingEngagementType
      ),
      tweetType: emptyValueToUndefined(v.others.tweetType),
      bidPricingUnit: v.others.bidPricingUnit,
      chargeBy: v.others.chargeBy,
    },
    promotedProductType: v.promotedProductType,
  }
}

export const decideRetargetingType = (
  v: RetargetingEngagementType | undefined
): RetargetingType | undefined => {
  if (v == null || v === "") {
    return
  }
  if (v === "IMPRESSION") {
    return "Tweet_Impression"
  } else {
    return "Tweet_Impression_and_Engagement"
  }
}

export const displayErrorMsgBidType = (
  errors: FormikErrors<CreateOrderCommonFormValues>,
  index: number
): string | undefined => {
  return getIn(errors, `adGroups.${index}.others.bidType`)
}

export const displayErrorMsgBidAmount = (
  errors: FormikErrors<CreateOrderCommonFormValues>,
  index: number
): string | undefined => {
  return getIn(errors, `adGroups.${index}.others.bidAmount`)
}

// TODO: nullチェックするため要調査
// export const displayErrorMsgBidPricingUnit = (
//   errors: FormikErrors<CreateOrderCommonFormValues>,
//   index: number
// ): string | undefined => {
//   return getIn(errors, `adGroups.${index}.others.bidPricingUnit`)
// }

// TODO: nullチェックするため要調査
// export const displayErrorMsgOptimizationPreference = (
//   errors: FormikErrors<CreateOrderCommonFormValues>,
//   index: number
// ): string | undefined => {
//   return getIn(errors, `adGroups.${index}.others.optimizationPreference`)
// }

// TODO: nullチェックするため要調査
// export const displayErrorMsgChargeBy = (
//   errors: FormikErrors<CreateOrderCommonFormValues>,
//   index: number
// ): string | undefined => {
//   return getIn(errors, `adGroups.${index}.others.chargeBy`)
// }
