import { Global, css } from "@emotion/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import React, { Fragment } from "react"

type OwnProps = {
  children?: never
}

/**
 * 全体に適用したいstyle
 */
export const GlobalStyles: React.FC<OwnProps> = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Global styles={globalStyles} />
    </Fragment>
  )
}

const globalStyles = css`
  body {
    min-width: 960px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Hiragino Sans",
      "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
  }
`
