import MenuItem from "@material-ui/core/MenuItem"
import { getIn, useFormikContext } from "formik"
import React from "react"
import { RadioField } from "src/components/atoms/RadioField"
import { AccordionPanelRow } from "src/components/molecules/AccordionPanelRow"
import { RadioFieldRow } from "src/components/molecules/RadioFieldRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const TargetingUserDetail: React.FC<OwnProps> = ({
  index,
  disabled,
}) => {
  const { setFieldValue, values, errors } = useFormikContext<CommonFormValues>()

  return (
    <AccordionPanelRow
      label={`性別 ${values.adGroups[index].others.gender} / 年齢 ${
        values.adGroups[index].others.age === ""
          ? "(未設定)"
          : values.adGroups[index].others.age
      } / 言語 ${values.adGroups[index].others.language} / 地域 ${
        values.adGroups[index].others.location
      }`}
    >
      <RadioFieldRow
        label="性別"
        value={values.adGroups[index].others.gender}
        fieldName={`adGroups.${index}.others.gender`}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.gender`, value)
        }
      >
        <RadioField label="男女" value="ANY_GENDER" disabled={disabled} />
        <RadioField label="男" value="MALE" disabled={disabled} />
        <RadioField label="女" value="FEMALE" disabled={disabled} />
      </RadioFieldRow>
      <TextFieldRow
        label="年齢"
        initialValue={values.adGroups[index].others.age}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.age`, value)
        }
        fieldName={`adGroups.${index}.others.age`}
        select
        disabled={disabled}
      >
        <MenuItem value="">全年齢</MenuItem>
        <MenuItem value="AGE_13_to_19">13～19歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_13_to_24">13～24歳</MenuItem>
        <MenuItem value="AGE_13_to_29">13～29歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_13_to_34">13～34歳</MenuItem>
        <MenuItem value="AGE_13_to_39">13～39歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_13_to_49">13～49歳</MenuItem>
        <MenuItem value="AGE_13_to_54">13～54歳</MenuItem>
        <MenuItem value="AGE_OVER_13">13歳以上</MenuItem>
        <MenuItem value="AGE_18_to_24">18～24歳</MenuItem>
        <MenuItem value="AGE_18_to_34">18～34歳</MenuItem>
        <MenuItem value="AGE_18_to_49">18～49歳</MenuItem>
        <MenuItem value="AGE_18_to_54">18～54歳</MenuItem>
        <MenuItem value="AGE_OVER_18">18歳以上</MenuItem>
        <MenuItem value="AGE_20_to_29">20～29歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_20_to_34">20～34歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_20_to_39">20～39歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_20_to_49">20～49歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_OVER_20">20歳以上（日本のみ）</MenuItem>
        <MenuItem value="AGE_21_to_34">21～34歳</MenuItem>
        <MenuItem value="AGE_21_to_49">21～49歳</MenuItem>
        <MenuItem value="AGE_21_to_54">21～54歳</MenuItem>
        <MenuItem value="AGE_OVER_21">21歳以上</MenuItem>
        <MenuItem value="AGE_25_to_49">25～49歳</MenuItem>
        <MenuItem value="AGE_25_to_54">25～54歳</MenuItem>
        <MenuItem value="AGE_OVER_25">25歳以上</MenuItem>
        <MenuItem value="AGE_30_to_39">30～39歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_30_to_49">30～49歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_OVER_30">30歳以上（日本のみ）</MenuItem>
        <MenuItem value="AGE_35_to_49">35～49歳</MenuItem>
        <MenuItem value="AGE_35_to_54">35～54歳</MenuItem>
        <MenuItem value="AGE_OVER_35">35歳以上</MenuItem>
        <MenuItem value="AGE_40_to_49">40～49歳（日本のみ）</MenuItem>
        <MenuItem value="AGE_OVER_40">40歳以上（日本のみ）</MenuItem>
        <MenuItem value="AGE_OVER_50">50歳以上</MenuItem>
      </TextFieldRow>
      <TextFieldRow
        label="言語"
        initialValue={values.adGroups[index].others.language}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.language`, value)
        }
        fieldName={`adGroups.${index}.others.language`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
        errorMsg={getIn(errors, `adGroups.${index}.others.language`)}
      />
      <TextFieldRow
        label="地域"
        initialValue={values.adGroups[index].others.location}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.location`, value)
        }
        fieldName={`adGroups.${index}.others.location`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
        errorMsg={getIn(errors, `adGroups.${index}.others.location`)}
      />
    </AccordionPanelRow>
  )
}
