import produce from "immer"

/**
 * セミコロン区切りの文字列を配列に変換する
 * # e.g.
 * - "a,b,c" -> ["a", "b", "c"]
 * - undefined -> []
 */
export const semicolonStringToArray = (
  semicolonSeparatedValue?: string
): string[] => {
  if (
    semicolonSeparatedValue == null ||
    semicolonSeparatedValue.trim() === ""
  ) {
    return []
  }
  const clone = produce(semicolonSeparatedValue, (draft) => {
    if (draft.substr(-1, 1) === ";") {
      return draft.slice(0, -1)
    }
    return draft
  })
  // 現在はセミコロン区切りの文字列を分割しているが、
  // 人によって入力の癖が異なるため
  // 改行やtabなど、;以外でも分割出来るようにした方が良いかもしれない
  return clone.split(";").map((k) => k.trim())
}

/**
 * 画面都合で入力される「空("")」を、API に都合がよい値(undefined)に変換する
 * # e.g.
 * - "" -> undefined
 * - "any_value" -> "any_value" (変換なし)
 */
export const emptyValueToUndefined = <T>(value: T | ""): T | undefined => {
  if (value === "") {
    return undefined
  }
  return value
}

/**
 * emptyValueToUndefined の反対
 * # e.g.
 * - undefined -> ""
 * - "any_value" -> "any_value" (変換なし)
 */
export const undefinedToEmptyValue = <T>(value: T | undefined): T | "" => {
  if (value == null) {
    return ""
  }
  return value
}
