/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import { getIn, useFormikContext } from "formik"
import React from "react"
import { FastTextField } from "src/components/atoms/FastTextField"
import { LabeledCheckbox } from "src/components/molecules/LabeledCheckbox"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const TargetingKeywords: React.FC<OwnProps> = ({ index, disabled }) => {
  const { setFieldValue, values, errors } = useFormikContext<CommonFormValues>()

  const errorMsg: string | undefined = getIn(
    errors,
    `adGroups.${index}.others.targetingKeywords`
  )

  return (
    <MultipleFieldRow label="ターゲティング内容">
      <LabeledCheckbox
        label="拡張を希望する"
        checked={values.adGroups[index].others.requestExpandBroadMatchKeywords}
        onSetValue={(value) => {
          setFieldValue(
            `adGroups.${index}.others.requestExpandBroadMatchKeywords`,
            value
          )
        }}
        disabled={disabled}
      />
      {!!errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      <FastTextField
        initialValue={values.adGroups[index].others.broadMatchKeywords}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.broadMatchKeywords`, value)
        }
        name={`adGroups.${index}.others.broadMatchKeywords`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="キーワード"
        css={input}
        disabled={disabled}
      />
      <FastTextField
        initialValue={values.adGroups[index].others.phraseKeywordsExcluded}
        onSetValue={(value) =>
          setFieldValue(
            `adGroups.${index}.others.phraseKeywordsExcluded`,
            value
          )
        }
        name={`adGroups.${index}.others.phraseKeywordsExcluded`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="除外するキーワード"
        color="secondary"
        css={input}
        disabled={disabled}
      />
      <FastTextField
        initialValue={values.adGroups[index].others.eventTargetingIds}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.eventTargetingIds`, value)
        }
        name={`adGroups.${index}.others.eventTargetingIds`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="イベント"
        css={input}
        disabled={disabled}
      />
      <FastTextField
        initialValue={values.adGroups[index].others.userInterests}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.userInterests`, value)
        }
        name={`adGroups.${index}.others.userInterests`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="ユーザーの興味関心"
        css={input}
        disabled={disabled}
      />
      <FastTextField
        initialValue={values.adGroups[index].others.followerTargetingLookalikes}
        onSetValue={(value) =>
          setFieldValue(
            `adGroups.${index}.others.followerTargetingLookalikes`,
            value
          )
        }
        name={`adGroups.${index}.others.followerTargetingLookalikes`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="フォロワー類似オーディエンス"
        css={input}
        disabled={disabled}
      />
      <FastTextField
        initialValue={values.adGroups[index].others.tvShows}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.tvShows`, value)
        }
        name={`adGroups.${index}.others.tvShows`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="映画とテレビ番組"
        css={input}
        disabled={disabled}
      />
      <FastTextField
        initialValue={values.adGroups[index].others.conversations}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.conversations`, value)
        }
        name={`adGroups.${index}.others.conversations`}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="セミコロン( ; )区切りで入力してください"
        label="会話"
        css={input}
        disabled={disabled}
      />
    </MultipleFieldRow>
  )
}

const input = css`
  margin-top: 8px;
`
