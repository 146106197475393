import format from "date-fns/format"
import parse from "date-fns/parse"
import toDate from "date-fns/toDate"
import { DateTimeFormatForAPI } from "src/dataLayer/apis/types"

/**
 * API に送信するための日付書式
 * （実質、画面の表示にも使ってる）
 * e.g. 2019-10-31 17:01
 */
export const DATE_TIME_FORMAT_FOR_API = "yyyy-MM-dd HH:mm"

/**
 * Date Object を生成する場合は、必ずこの function を使うこと。
 */
export const getNow = (): Date => {
  // eslint-disable-next-line no-restricted-globals
  return toDate(new Date())
}

/**
 * @throws 規定の日付書式でない場合
 */
export const toDateFromAPI = (dateTimeStr: DateTimeFormatForAPI): Date => {
  // eslint-disable-next-line no-restricted-globals
  const parsed = parse(dateTimeStr, DATE_TIME_FORMAT_FOR_API, new Date())
  // @see https://qiita.com/muddydixon/items/2edf6dcb84295eccf4f3
  if (parsed.toString() === "Invalid Date") {
    throw new Error(`Invalid DateTime value. value: ${dateTimeStr}`)
  }
  return parsed
}

/**
 * API リクエスト用の日付形式に変換して返す
 */
export const formatDateAsAPI = (
  date: Date | undefined
): DateTimeFormatForAPI | null => {
  if (date == null) {
    return null
  }
  return format(date, DATE_TIME_FORMAT_FOR_API) as DateTimeFormatForAPI
}
