import {
  AnyAction,
  DeepPartial,
  Store,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux"
import immutableStateInvariantMiddleware from "redux-immutable-state-invariant"
import { createSerializableStateInvariantMiddleware } from "redux-starter-kit"
import thunkMiddleWare from "redux-thunk"
import { isProd } from "src/constants/Env"
import { twitterAPIReducer, twitterAPIState } from "src/ducks/twitterAPI"

import { CampaignState, campaignReducer } from "./campaign"
import { SettingsState, settingsReducer } from "./settings"

export type RootState = Readonly<{
  campaign: CampaignState
  settings: SettingsState
  twitterAPI: twitterAPIState
}>

export const configureStore = (
  initialState: DeepPartial<RootState> = {}
): Store<RootState, AnyAction> => {
  const rootReducer = combineReducers<RootState>({
    campaign: campaignReducer,
    settings: settingsReducer,
    twitterAPI: twitterAPIReducer,
  })

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const middleWares = []
  middleWares.push(thunkMiddleWare)
  if (!isProd) {
    middleWares.push(immutableStateInvariantMiddleware())
    middleWares.push(createSerializableStateInvariantMiddleware())
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleWares))
  )

  return store
}
