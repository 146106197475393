import produce from "immer"
import { Action } from "redux"
import {
  Account,
  AccountId,
  PublishedCampaign,
} from "src/domainLayer/models/TwitterAPI"
import { isType } from "typescript-fsa"

import * as actions from "./actions"

export type State = Readonly<{
  accountList: Account[]
  campaignList: PublishedCampaign[]
  isLoadingAccountList: boolean
  isLoadingCampaignList: boolean
  selectedAccount: AccountId | undefined
}>

const initialState: State = {
  isLoadingAccountList: true,
  isLoadingCampaignList: true,
  campaignList: [],
  accountList: [],
  selectedAccount: undefined,
}

export const reducers = (state = initialState, action: Action): State => {
  if (isType(action, actions.fetchAccountList.started)) {
    return produce(state, (draft) => {
      draft.isLoadingAccountList = true
      draft.campaignList = []
    })
  }
  if (isType(action, actions.fetchAccountList.done)) {
    return produce(state, (draft) => {
      const { result } = action.payload
      draft.accountList = result
      draft.isLoadingAccountList = false
    })
  }
  if (isType(action, actions.fetchAccountList.failed)) {
    return produce(state, () => {
      // const { error } = action.payload
      // TODO エラーハンドリング
      console.error("アカウント情報の取得に失敗")
    })
  }
  /**
   * TwitterAPIからキャンペーン一覧を取得
   */
  if (isType(action, actions.fetchPublishedCampaignList.started)) {
    return produce(state, (draft) => {
      draft.isLoadingCampaignList = true
    })
  }
  if (isType(action, actions.fetchPublishedCampaignList.done)) {
    return produce(state, (draft) => {
      const { result, params } = action.payload
      draft.campaignList = result
      draft.isLoadingCampaignList = false
      draft.selectedAccount = params.accountId
    })
  }
  if (isType(action, actions.fetchPublishedCampaignList.failed)) {
    return produce(state, () => {
      // const { error } = action.payload
      // TODO エラーハンドリング
      console.error("アカウント情報の取得に失敗")
    })
  }
  /**
   * TwitterAPIからキャンペーン一覧をリフレッシュする
   */
  if (isType(action, actions.refreshPublishedCampaignList)) {
    return produce(state, (draft) => {
      draft.campaignList = []
    })
  }

  return state
}
