/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import {
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { FieldArray, useFormikContext } from "formik"
import React, { Fragment } from "react"
import { useDispatch } from "react-redux"
import { AdsGroupInfoForm } from "src/components/organisms/AdsGroupInfoForm"
import {
  AdGroup,
  CommonFormValues,
} from "src/components/pages/EditRequestOrderDetail/form/types/CommonFormValues"
import { convertPutAdGroupReqByOperator } from "src/components/pages/EditRequestOrderDetail/form/utils"
import { InhouseAdGroupId } from "src/domainLayer/models/AdsGroupInfo"
import {
  InhouseCampaignId,
  InhouseCampaignStatus,
} from "src/domainLayer/models/CampaignInfo"
import { campaignOperations } from "src/ducks/campaign"

type OwnProps = {
  children?: never
  inhouseCampaignStatus: InhouseCampaignStatus | undefined
  selectSendBackAdGroup: (
    e: React.ChangeEvent<HTMLInputElement>,
    inhouseAdGroupId: InhouseAdGroupId
  ) => void
}
/**
 * 編集画面 広告グループ情報入力エリア
 */
export const EditAdsGroupsInfo: React.FC<OwnProps> = ({
  inhouseCampaignStatus,
  selectSendBackAdGroup,
}) => {
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()
  const dispatch = useDispatch()

  const updateOrNewAdGroup = (
    parentId: InhouseCampaignId,
    target: AdGroup,
    index: number
  ): void => {
    if (!target.isEdit) {
      setFieldValue(`adGroups.${index}.isEdit`, true)
      return
    }

    setFieldValue(`adGroups.${index}.isEdit`, false)

    dispatch(
      campaignOperations.updateRelatedAdGroups(
        convertPutAdGroupReqByOperator(target, parentId)
      )
    )
  }

  return (
    <FieldArray
      name="adGroups"
      render={() =>
        values.adGroups.map((adGroup, index) => (
          // 新規作成の場合は、他にユニークなIDがないため
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    selectSendBackAdGroup(e, adGroup.inhouseAdGroupId)
                  }}
                  value={adGroup.inhouseAdGroupId}
                  color="secondary"
                />
              }
              label="差し戻す"
            />
            <ExpansionPanel defaultExpanded elevation={0} css={adGroupPanel}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>広告グループ {adGroup.adGroupName}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails css={adsGroupContent}>
                <div css={buttonGroup}>
                  <Button
                    size="small"
                    color="primary"
                    css={button}
                    href="https://maketheworldhotto.sharepoint.com/:x:/r/Shared%20Documents/02.Function%20(%E6%A9%9F%E8%83%BD)/03_%E9%96%8B%E7%99%BA/19_%E7%A4%BE%E5%86%85%E5%BA%83%E5%91%8A%E9%81%8B%E7%94%A8%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E5%8C%96/01_%E5%88%9D%E6%9C%9F%E3%83%AA%E3%83%AA%E3%83%BC%E3%82%B9/%E3%83%90%E3%83%AB%E3%82%AF%E3%82%B7%E3%83%BC%E3%83%88%E5%90%84%E5%80%A4%E3%81%AE%E5%8F%82%E7%85%A7%E5%85%88/syntax_sheet_1579197646_ja.xlsx?d=wb2126782b01644b5b40865b6e1780236&csf=1&e=jYviKe"
                    target="_blank"
                  >
                    Excelから値を参照する
                  </Button>
                  <Button
                    size="small"
                    variant={adGroup.isEdit ? "contained" : "outlined"}
                    color="primary"
                    css={button}
                    disabled={inhouseCampaignStatus === 3}
                    onClick={() =>
                      updateOrNewAdGroup(
                        values.campaign.inhouseCampaignId,
                        adGroup,
                        index
                      )
                    }
                  >
                    {/* ユーザーから見て「新規作成で保存」or「編集で保存」を見分ける必要性がないと思われるため、同じ文言にしている */}
                    {adGroup.isEdit ? "保存する" : "編集する"}
                  </Button>
                </div>
                <AdsGroupInfoForm disabled={!adGroup.isEdit} index={index} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Fragment>
        ))
      }
    />
  )
}

const adGroupPanel = css`
  margin-bottom: 30px;
  &:before {
    display: none;
  }
`
const adsGroupContent = css`
  padding: 0;
`
const buttonGroup = css`
  position: absolute;
  z-index: 1;
  top: 17px;
  right: 70px;
`
const button = css`
  margin-left: 8px;
`
