import { Auth } from "aws-amplify"
import { FetchUserResp } from "src/dataLayer/apis/CognitoAPI/types/FetchUserResp"
import { AuthorizationToken } from "src/dataLayer/apis/types"
import { InhouseUserRole } from "src/domainLayer/models/UserInfo"
import { AuthCurrentUserInfo } from "src/types/amplify"

/**
 * Cognitoで管理しているログイン中のユーザー情報を取得して返す
 */
export const fetchUserInfo = async (): Promise<FetchUserResp> => {
  const u = (await Auth.currentUserInfo()) as AuthCurrentUserInfo
  return {
    user_role: u.attributes["custom:user_role"] as InhouseUserRole,
  }
}

/**
 * Cognito の認証トークンを取得（もしくはキャッシュから読み出し）する。
 * Cognito の仕様でトークンが定期的に期限切れするため、使うタイミングで取得し直す必要がある。
 * （再取得・リフレッシュは、ライブラリが自動でやってくれる）
 * そのため、このトークンを Cookie や Redux state に保持してはダメ。
 *
 * # メモ
 * ID Token: 認証に使用する文字列
 * Access Token: ユーザープールのユーザー属性の追加・変更・削除に使用する文字列
 * Refresh Token: 更新トークンは主に新しいIDトークンおよびアクセストークンの取得に使用する文字列
 */
export const fetchOrGetIdToken = async (): Promise<AuthorizationToken> => {
  const resp = await Auth.currentSession()
  return resp.getIdToken().getJwtToken() as AuthorizationToken
}
