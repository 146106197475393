import { DateTimeFormatForAPI } from "src/dataLayer/apis/types"
import { AdGroupInner, BaseAdGroup } from "src/domainLayer/models/AdsGroupInfo"
import { Brand, CastAny } from "src/types/Utils"

/**
 * キャンペーン情報関連
 */

/**
 * 社内用キャンペーンID
 */
export type InhouseCampaignId = Brand<number, "InhouseCampaignId">

/**
 * string な InhouseCampaignId を受け入れ可能にするため、独自の assert function にしている
 */
export const assertAndConvertInhouseCampaignId = (
  maybeId: unknown
): InhouseCampaignId => {
  const converted = Number(maybeId)
  if (isNaN(converted)) {
    throw new Error(`${maybeId} is not InhouseCampaignId`)
  }
  return converted as InhouseCampaignId
}

/**
 * 1: 入稿依頼新規作成
 * 2: 入稿依頼下書き 保存済み
 * 3: 入稿依頼 運用チームへ提出済み
 * 4: 入稿依頼 運用チーム 下書き保存済み
 * 5: 入稿準備完了
 * 6: バルクシート反映済み
 */
export type InhouseCampaignStatus = 1 | 2 | 3 | 4 | 5 | 6

/**
 * キャンペーンID
 * 既に登録済みのキャンペーンに対し、自動で付与される
 */
export type CampaignId = Brand<string, "CampaignId">

/**
 * キャンペーンの目的
 */
export type CampaignObjective =
  | "APP_ENGAGEMENTS"
  | "APP_INSTALLS"
  | "AWARENESS"
  | "FOLLOWERS"
  | "IN_STREAM_VIDEO_AD_VIEWS_MIDROLL"
  | "IN_STREAM_VIDEO_AD_VIEWS_PREROLL"
  | "PROMOTED_VIDEO_VIEWS"
  | "QUALIFIED_VIEWS"
  | "TWEET_ENGAGEMENTS"
  | "WEBSITE_CLICKS"

export const CampaignObjectiveValues: readonly CampaignObjective[] = [
  "APP_ENGAGEMENTS",
  "APP_INSTALLS",
  "AWARENESS",
  "FOLLOWERS",
  "IN_STREAM_VIDEO_AD_VIEWS_MIDROLL",
  "IN_STREAM_VIDEO_AD_VIEWS_PREROLL",
  "PROMOTED_VIDEO_VIEWS",
  "QUALIFIED_VIEWS",
  "TWEET_ENGAGEMENTS",
  "WEBSITE_CLICKS",
]

export function assertIsCampaignObjective(
  x: CastAny
): asserts x is CampaignObjective {
  if (!CampaignObjectiveValues.includes(x)) {
    throw new Error(`"${x}" is not valid CampaignObjective`)
  }
}

/**
 * 営業側入稿依頼時、入力必須のキャンペーン情報
 */
export type RequiredCampaignInfo = {
  /**
   * リファレンス番号
   */
  campaignEndDate: Date
  campaignName: string
  campaignObjective: CampaignObjective
  campaignStartDate: Date
  campaignTotalBudget: number
  /**
   * お支払い方法ID
   */
  fundingSourceId: string
}

/**
 * オペレータ出稿依頼時、入力必須のキャンペーン情報
 */
export type FixedCampaignInfo = {
  /**
   * キャンペーンの日別予算
   */
  campaignDailyBudget: number
  /**
   * 配信ペース
   */
  delivery: boolean
}

/**
 * 出稿依頼時、必須項目でないキャンペーン情報
 */
export type NullAbleCampaignInfo = {
  /**
   * アプリ名
   */
  campaignAppId: string
  /**
   * キャンペーンのフリークエンシー上限
   */
  campaignFrequencyCap: string
  /**
   * キャンペーンのフリークエンシー上限が適用される期間
   */
  campaignFrequencyCapTimeDuration: string
}

/**
 * サマリ表示（一覧など）に使う型
 */
export type CampaignSummary = {
  campaignEndDate: DateTimeFormatForAPI
  campaignName: string
  campaignStartDate: DateTimeFormatForAPI
  campaignTotalBudget: number
  clientName: string
  inhouseCampaignId: InhouseCampaignId
  inhouseCampaignStatus: InhouseCampaignStatus
  inhouseOrderCreatedAt: string
  inhouseOrderCreatedBy: string
  inhouseOrderSendBackFlag: boolean
  inhouseOrderStatus: InhouseCampaignStatus
  inhouseOrderUpdatedAt: string | undefined
  inhouseOrderUpdatedBy: string | undefined
  publishedFlag: boolean
  sendBackFlag: boolean
}

/**
 * 詳細表示・編集などに使う型
 */
export type CampaignDetail = {
  adGroups: AdGroupInner[]
  campaign: CampaignDetailInner
}

export type CampaignDetailInner = BaseCampaign & InhouseCampaign

/**
 * 継続新規作成の際の詳細表示・編集などに使う型
 */
export type PublishedCampaignDetail = {
  adGroups: BaseAdGroup[]
  campaign: BaseCampaign
}

export type BaseCampaign = {
  campaignAppId?: string[]
  campaignDailyBudget?: number
  campaignEndDate: DateTimeFormatForAPI
  campaignFrequencyCap?: string
  campaignFrequencyCapTimeDuration?: string
  campaignId?: CampaignId
  campaignName?: string
  campaignObjective?: CampaignObjective
  campaignStartDate: DateTimeFormatForAPI
  campaignStatus: string
  campaignTotalBudget?: number
  clientName?: string
  consultation: boolean
  delivery: boolean
  fundingSourceId?: string
  grossBudget?: number
  inhouseOrderCreatedAt: string
  inhouseOrderCreatedBy: string
  inhouseOrderUpdatedAt: string | undefined
  inhouseOrderUpdatedBy: string | undefined
  twitterAccountName?: string
}

/**
 * 社内用のキャンペーンパラメータ
 */
type InhouseCampaign = {
  inhouseCampaignId: InhouseCampaignId
  inhouseCampaignStatus: InhouseCampaignStatus
  inhouseEditable: boolean
  inhouseOrderStatus: InhouseCampaignStatus
  publishedFlag: boolean
  sendBackFlag: boolean
}

export type AdGroupPlacement =
  // Twitterプロフィールとツイートの詳細
  | "PROFILES"
  // 検索結果
  | "SEARCH_RESULTS"
  // タイムライン
  | "TIMELINES"

export const AdGroupPlacementValues: readonly AdGroupPlacement[] = [
  "PROFILES",
  "SEARCH_RESULTS",
  "TIMELINES",
]

export function assertIsAdGroupPlacement(
  x: CastAny
): asserts x is AdGroupPlacement {
  if (!AdGroupPlacementValues.includes(x)) {
    throw new Error(`"${x}" is not valid AdGroupPlacement`)
  }
}

export type Age =
  | "AGE_13_to_19"
  | "AGE_13_to_24"
  | "AGE_13_to_29"
  | "AGE_13_to_34"
  | "AGE_13_to_39"
  | "AGE_13_to_49"
  | "AGE_13_to_54"
  | "AGE_OVER_13"
  | "AGE_18_to_24"
  | "AGE_18_to_34"
  | "AGE_18_to_49"
  | "AGE_18_to_54"
  | "AGE_OVER_18"
  | "AGE_20_to_29"
  | "AGE_20_to_34"
  | "AGE_20_to_39"
  | "AGE_20_to_49"
  | "AGE_OVER_20"
  | "AGE_21_to_34"
  | "AGE_21_to_49"
  | "AGE_21_to_54"
  | "AGE_OVER_21"
  | "AGE_25_to_49"
  | "AGE_25_to_54"
  | "AGE_OVER_25"
  | "AGE_30_to_39"
  | "AGE_30_to_49"
  | "AGE_OVER_30"
  | "AGE_35_to_49"
  | "AGE_35_to_54"
  | "AGE_OVER_35"
  | "AGE_40_to_49"
  | "AGE_OVER_40"
  | "AGE_OVER_50"

export type DeviceActivationDuration =
  | "LESS_THAN 30.days"
  | "LESS_THAN 60.days"
  | "LESS_THAN 90.days"
  | "LESS_THAN 120.days"
  | "LESS_THAN 150.days"
  | "LESS_THAN 180.days"
  | "GREATER_THAN_OR_EQUAL_TO 30.days"
  | "GREATER_THAN_OR_EQUAL_TO 60.days"
  | "GREATER_THAN_OR_EQUAL_TO 90.days"
  | "GREATER_THAN_OR_EQUAL_TO 120.days"
  | "GREATER_THAN_OR_EQUAL_TO 150.days"
  | "GREATER_THAN_OR_EQUAL_TO 180.days"

export type Gender = "ANY_GENDER" | "MALE" | "FEMALE"

export type LookalikeExpansionSetting = "DEFINED" | "EXPANDED" | "BROAD"

/**
 * 配信ペース
 */
export type Delivery = "standard_delivery" | "centralized_delivery"

/**
 * コンサル実施
 */
export type Consultation = "implementation" | "not_implementation"
