/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Button from "@material-ui/core/Button"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { FieldArray, useFormikContext } from "formik"
import React from "react"
import { AdsGroupInfoForm } from "src/components/organisms/AdsGroupInfoForm"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
}
/**
 * 広告グループ情報入力エリア
 */
export const AdsGroupsInfo: React.FC<OwnProps> = () => {
  const { values } = useFormikContext<CommonFormValues>()
  return (
    <FieldArray
      name="adGroups"
      render={({ remove, push }) => (
        <div>
          {values.adGroups.map((adGroup, index) => (
            <ExpansionPanel
              // 新規作成の場合は、他にユニークなIDがないため
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              defaultExpanded
              elevation={0}
              css={adsGroup}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>広告グループ {adGroup.adGroupName}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails css={adsGroupContent}>
                <div css={buttonGroup}>
                  <Button
                    size="small"
                    color="primary"
                    css={button}
                    href="https://maketheworldhotto.sharepoint.com/:x:/r/Shared%20Documents/02.Function%20(%E6%A9%9F%E8%83%BD)/03_%E9%96%8B%E7%99%BA/19_%E7%A4%BE%E5%86%85%E5%BA%83%E5%91%8A%E9%81%8B%E7%94%A8%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E5%8C%96/01_%E5%88%9D%E6%9C%9F%E3%83%AA%E3%83%AA%E3%83%BC%E3%82%B9/%E3%83%90%E3%83%AB%E3%82%AF%E3%82%B7%E3%83%BC%E3%83%88%E5%90%84%E5%80%A4%E3%81%AE%E5%8F%82%E7%85%A7%E5%85%88/syntax_sheet_1579197646_ja.xlsx?d=wb2126782b01644b5b40865b6e1780236&csf=1&e=jYviKe"
                    target="_blank"
                  >
                    Excelから値を参照する
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    css={button}
                    onClick={() => remove(index)}
                    disabled={values.adGroups.length === 1}
                  >
                    削除する
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    css={button}
                    onClick={() => {
                      // formik が内部で deepClone してるため、そのまま渡してよい
                      push(adGroup)
                    }}
                  >
                    コピー
                  </Button>
                </div>
                <AdsGroupInfoForm index={index} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      )}
    />
  )
}

const adsGroup = css`
  margin-bottom: 30px;
  &:before {
    display: none;
  }
`
const adsGroupContent = css`
  padding: 0;
`
const buttonGroup = css`
  position: absolute;
  z-index: 1;
  top: 17px;
  right: 70px;
`
const button = css`
  margin-left: 8px;
`
