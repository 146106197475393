import axios from "axios"
import { INHOUSE_ADS_API_PATH } from "src/constants/Env"
import { PostAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PostAdGroupsReq"
import { PutAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PutAdGroupsReq"
import { AuthorizationToken } from "src/dataLayer/apis/types"
import { InhouseAdGroupId } from "src/domainLayer/models/AdsGroupInfo"
import { InhouseCampaignId } from "src/domainLayer/models/CampaignInfo"

/**
 * 広告に関する API
 */

/**
 * （特別な理由がなければ）共通で使う URL パラメータ
 * inhouse_ad_group_idは広告グループをpostする時id存在しないため or undefined とした
 */
export type UrlParams = {
  inhouse_ad_group_id?: InhouseAdGroupId
  inhouse_campaign_id: InhouseCampaignId
}

/**
 * 広告(単体)の更新
 * APIの定義の都合、bodyの中身は配列だが、URLパラメータは単体の広告を指している
 * (単体か複数か、どっちの更新のエンドポイントかはっきりさせるべき)
 *
 * PUT /documents/update/{inhouse_campaign_id}/{inhouse_ad_group_id}
 * @param authToken 認証トークン
 * @param urlParams URL中のパラメータ
 * @param queryStrings GETパラメータ
 * @param requestBody POSTパラメータ
 */
export const putAdGroups = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams,
  requestBody: PutAdGroupsReq
): Promise<void> => {
  await axios.put<void>(
    `${INHOUSE_ADS_API_PATH}/documents/update/${urlParams.inhouse_campaign_id}/${urlParams.inhouse_ad_group_id}`,
    requestBody,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
}
/**
 * 広告(複数)の新規作成
 * POST /documents/insert/ad_groups/{inhouse_campaign_id}
 * @param authToken
 * @param urlParams 新規作成時点では inhouse_ad_group_id は存在しないため、Omit している
 * @param requestBody
 */
export const postAdGroups = async (
  authToken: AuthorizationToken,
  urlParams: Omit<UrlParams, "inhouse_ad_group_id">,
  requestBody: PostAdGroupsReq
): Promise<InhouseAdGroupId> => {
  const resp = await axios.post<InhouseAdGroupId>(
    `${INHOUSE_ADS_API_PATH}/documents/insert/ad_groups/${urlParams.inhouse_campaign_id}`,
    requestBody,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}

/**
 * 広告(単体)の削除
 * DELETE /documents/delete/{inhouse_campaign_id}/{inhouse_ad_group_id}
 */
export const deleteAdGroup = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams
): Promise<void> => {
  await axios.delete<void>(
    `${INHOUSE_ADS_API_PATH}/documents/delete/${urlParams.inhouse_campaign_id}/${urlParams.inhouse_ad_group_id}`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
}
