import MenuItem from "@material-ui/core/MenuItem"
import { useFormikContext } from "formik"
import React from "react"
import { AccordionPanelRow } from "src/components/molecules/AccordionPanelRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const UserDeviceDetail: React.FC<OwnProps> = ({ index, disabled }) => {
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()

  return (
    <AccordionPanelRow
      label={`OSバージョン / プラットフォーム  / 端末モデル / 携帯電話会社`}
    >
      <TextFieldRow
        label="OSバージョン"
        initialValue={values.adGroups[index].others.userOsVersion}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.userOsVersion`, value)
        }
        fieldName={`adGroups.${index}.others.userOsVersion`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
      />
      <TextFieldRow
        label="プラットフォーム"
        initialValue={values.adGroups[index].others.platform}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.platform`, value)
        }
        fieldName={`adGroups.${index}.others.platform`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
      />
      <TextFieldRow
        label="端末モデル"
        initialValue={values.adGroups[index].others.userDevice}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.userDevice`, value)
        }
        fieldName={`adGroups.${index}.others.userDevice`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
      />
      <TextFieldRow
        label="携帯電話会社"
        initialValue={values.adGroups[index].others.mobileCarriers}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.mobileCarriers`, value)
        }
        fieldName={`adGroups.${index}.others.mobileCarriers`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
      />
      <TextFieldRow
        label="次の期間に新しい端末を購入した利用者をターゲティング"
        initialValue={
          values.adGroups[index].others.deviceActivationDuration != null
            ? values.adGroups[index].others.deviceActivationDuration
            : ""
        }
        onSetValue={(value) =>
          setFieldValue(
            `adGroups.${index}.others.deviceActivationDuration`,
            value
          )
        }
        fieldName={`adGroups.${index}.others.deviceActivationDuration`}
        select
        disabled={disabled}
      >
        <MenuItem value="">選択してください</MenuItem>
        <MenuItem value="LESS_THAN 30.days">過去 1ヶ月</MenuItem>
        <MenuItem value="LESS_THAN 60.days">過去 2ヶ月</MenuItem>
        <MenuItem value="LESS_THAN 90.days">過去 3ヶ月</MenuItem>
        <MenuItem value="LESS_THAN 120.days">過去 4ヶ月</MenuItem>
        <MenuItem value="LESS_THAN 150.days">過去 5ヶ月</MenuItem>
        <MenuItem value="LESS_THAN 180.days">過去 6ヶ月</MenuItem>
        <MenuItem value="GREATER_THAN_OR_EQUAL_TO 30.days">
          1ヶ月 より大きい
        </MenuItem>
        <MenuItem value="GREATER_THAN_OR_EQUAL_TO 60.days">
          2ヶ月 より大きい
        </MenuItem>
        <MenuItem value="GREATER_THAN_OR_EQUAL_TO 90.days">
          3ヶ月 より大きい
        </MenuItem>
        <MenuItem value="GREATER_THAN_OR_EQUAL_TO 120.days">
          4ヶ月 より大きい
        </MenuItem>
        <MenuItem value="GREATER_THAN_OR_EQUAL_TO 150.days">
          5ヶ月 より大きい
        </MenuItem>
        <MenuItem value="GREATER_THAN_OR_EQUAL_TO 180.days">
          6ヶ月 より大きい
        </MenuItem>
      </TextFieldRow>
    </AccordionPanelRow>
  )
}
