/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Button from "@material-ui/core/Button"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { FieldArray, useFormikContext } from "formik"
import produce from "immer"
import React from "react"
import { useDispatch } from "react-redux"
import * as Scroll from "react-scroll"
import {
  AdGroup,
  CommonFormValues,
} from "src/components/helpers/FormUtils/types/CommonFormValues"
import { AdsGroupInfoForm } from "src/components/organisms/AdsGroupInfoForm"
import {
  convertPostAdGroupReqBySales,
  convertPutAdGroupReqBySales,
} from "src/components/pages/EditOrder/form/utils"
import { InhouseCampaignId } from "src/domainLayer/models/CampaignInfo"
import { campaignOperations } from "src/ducks/campaign"

type OwnProps = {
  children?: never
}

export const EditAdsGroupsInfo: React.FC<OwnProps> = () => {
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()
  const dispatch = useDispatch()

  const updateOrNewAdGroup = async (
    parentId: InhouseCampaignId,
    target: AdGroup,
    index: number
  ): Promise<void> => {
    if (!target.isEdit) {
      setFieldValue(`adGroups.${index}.isEdit`, true)
      return
    }

    setFieldValue(`adGroups.${index}.isEdit`, false)

    // 更新
    await dispatch(
      campaignOperations.updateRelatedAdGroups(
        convertPutAdGroupReqBySales(target, parentId)
      )
    )
  }

  const deleteAdGroup = (
    parentId: InhouseCampaignId,
    target: AdGroup,
    remove: () => void
  ): void => {
    const isOk = window.confirm("この広告グループを削除してよろしいですか?")
    if (!isOk) {
      return
    }
    // 未登録データ（画面上から消せばよい）の場合
    if (target.inhouseAdGroupId == null) {
      remove()
      return
    }

    dispatch(
      campaignOperations.deleteAdGroup(parentId, target.inhouseAdGroupId, () =>
        remove()
      )
    )
  }

  const copyAdGroup = async (
    target: AdGroup,
    parentId: InhouseCampaignId
  ): Promise<void> => {
    const clone = produce(target, (draft) => {
      draft.inhouseAdGroupId = undefined
      // 広告グループ複製し新規作成した時、ステータスの初期値を 2、且つ、差し戻しなしとする
      draft.inhouseAdGroupStatus = 2
      draft.sendBackFlag = false
      draft.adGroupName += " - コピー"
    })
    await dispatch(
      campaignOperations.createRelatedAdGroups(
        convertPostAdGroupReqBySales(clone, parentId)
      )
    )
    // 発行された広告の ID を取得するため、再取得
    await dispatch(campaignOperations.fetchCampaignDetail(parentId))

    Scroll.scroller.scrollTo(`${values.adGroups.length}`, {
      duration: 1500,
      smooth: true,
    })
  }

  return (
    <FieldArray
      name="adGroups"
      render={({ remove }) =>
        values.adGroups
          // 広告グループを追加直後、POSTし再取得、再描画しているため、広告グループIDは必ず存在する
          .sort((a, b) => a.inhouseAdGroupId! - b.inhouseAdGroupId!)
          .map((adGroup, index) => (
            <Scroll.Element
              // 新規作成の場合は、他にユニークなIDがないため
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              name={`${index}`}
            >
              <div css={adGroupBlock}>
                <ExpansionPanel
                  defaultExpanded
                  elevation={0}
                  css={adGroupPanel}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>広告グループ {adGroup.adGroupName}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails css={adsGroupContent}>
                    <div css={buttonGroup}>
                      <Button
                        size="small"
                        color="primary"
                        css={button}
                        href="https://maketheworldhotto.sharepoint.com/:x:/r/Shared%20Documents/02.Function%20(%E6%A9%9F%E8%83%BD)/03_%E9%96%8B%E7%99%BA/19_%E7%A4%BE%E5%86%85%E5%BA%83%E5%91%8A%E9%81%8B%E7%94%A8%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E5%8C%96/01_%E5%88%9D%E6%9C%9F%E3%83%AA%E3%83%AA%E3%83%BC%E3%82%B9/%E3%83%90%E3%83%AB%E3%82%AF%E3%82%B7%E3%83%BC%E3%83%88%E5%90%84%E5%80%A4%E3%81%AE%E5%8F%82%E7%85%A7%E5%85%88/syntax_sheet_1579197646_ja.xlsx?d=wb2126782b01644b5b40865b6e1780236&csf=1&e=jYviKe"
                        target="_blank"
                      >
                        Excelから値を参照する
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        css={button}
                        disabled={
                          values.adGroups.length === 1 ||
                          adGroup.inhouseAdGroupStatus >= 3
                        }
                        onClick={() =>
                          deleteAdGroup(
                            values.campaign.inhouseCampaignId,
                            adGroup,
                            () => remove(index)
                          )
                        }
                      >
                        削除する
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        css={button}
                        onClick={() =>
                          copyAdGroup(
                            adGroup,
                            values.campaign.inhouseCampaignId
                          )
                        }
                      >
                        コピー
                      </Button>
                      <Button
                        size="small"
                        variant={adGroup.isEdit ? "contained" : "outlined"}
                        color="primary"
                        css={button}
                        disabled={adGroup.inhouseAdGroupStatus >= 3}
                        onClick={() =>
                          updateOrNewAdGroup(
                            values.campaign.inhouseCampaignId,
                            adGroup,
                            index
                          )
                        }
                      >
                        {/* ユーザーから見て「新規作成で保存」or「編集で保存」を見分ける必要性がないと思われるため、同じ文言にしている */}
                        {adGroup.isEdit ? "保存する" : "編集する"}
                      </Button>
                    </div>
                    <AdsGroupInfoForm
                      disabled={
                        !adGroup.isEdit || adGroup.inhouseAdGroupStatus >= 3
                      }
                      index={index}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </Scroll.Element>
          ))
      }
    />
  )
}

const adGroupPanel = css`
  &:before {
    display: none;
  }
`
const adsGroupContent = css`
  padding: 0;
`
const adGroupBlock = css`
  margin-bottom: 30px;
`
const buttonGroup = css`
  position: absolute;
  z-index: 1;
  top: 17px;
  right: 70px;
`
const button = css`
  margin-left: 8px;
`
