import * as operations from "./operations"
import { State, reducers } from "./reducers"
import * as selectors from "./selectors"

/**
 * このアプリケーションの設定系の state
 */

export const settingsOperations = operations
export const settingsReducer = reducers
export const settingsSelectors = selectors

export type SettingsState = State
