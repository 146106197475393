import { FormHelperText } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { getIn, useFormikContext } from "formik"
import React from "react"
import { useSelector } from "react-redux"
import Select, { ValueType } from "react-select"
import { RadioField } from "src/components/atoms/RadioField"
import { CommonFormValues } from "src/components/helpers/FormUtils/types/CommonFormValues"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { NumberFieldRow } from "src/components/molecules/NumberFieldRow"
import { RadioFieldRow } from "src/components/molecules/RadioFieldRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { RootState } from "src/ducks/store"

type OwnProps = {
  children?: never
  disabled?: boolean
}
type OptionType = {
  label: string
  value: string
}

/**
 * 入稿依頼編集用のクライアント情報入力エリア
 */
export const ClientInfo: React.FC<OwnProps> = ({ disabled }) => {
  const { values, setFieldValue, errors } = useFormikContext<CommonFormValues>()

  const accountList = useSelector(
    (state: RootState) => state.twitterAPI.accountList
  )
  const accountOptions: OptionType[] = accountList.map((account) => ({
    value: account.name,
    label: account.name,
  }))
  const selectedAccountName = accountOptions.find(
    (a) => a.value === values.campaign.twitterAccountName
  )

  const onChange = (accountValue: ValueType<OptionType>): void => {
    if (accountValue == null) {
      setFieldValue("campaign.twitterAccountName", "")
    }
    setFieldValue(
      "campaign.twitterAccountName",
      (accountValue as OptionType).value
    )
  }

  const errorMsg: string | undefined = getIn(
    errors,
    "campaign.twitterAccountName"
  )

  return (
    <Table>
      <TableBody>
        <TextFieldRow
          label="広告主"
          initialValue={values.campaign.clientName}
          onSetValue={(value) => setFieldValue("campaign.clientName", value)}
          fieldName="campaign.clientName"
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.clientName")}
        />
        <MultipleFieldRow label="アカウント名">
          <Select
            isSearchable
            options={accountOptions}
            value={selectedAccountName}
            onChange={onChange}
            name="campaign.twitterAccountName"
            isDisabled={disabled}
            placeholder="アカウントを選択してください"
          />
          {!!errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
        </MultipleFieldRow>
        <RadioFieldRow
          label="コンサル実施"
          value={values.campaign.consultation}
          fieldName="campaign.consultation"
          onSetValue={(value) => setFieldValue("campaign.consultation", value)}
        >
          <RadioField label="あり" value="implementation" disabled={disabled} />
          <RadioField
            label="なし"
            value="not_implementation"
            disabled={disabled}
          />
        </RadioFieldRow>
        <NumberFieldRow
          label="キャンペーン予算(グロス)"
          initialValue={values.campaign.grossBudget}
          onSetValue={(value) => setFieldValue("campaign.grossBudget", value)}
          fieldName="campaign.grossBudget"
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.grossBudget")}
        />
      </TableBody>
    </Table>
  )
}
