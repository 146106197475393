/** @jsx jsx */
import { jsx } from "@emotion/core"
import { IconButton, Snackbar } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import { Fragment, useState } from "react"
import { fetchCreativeIds } from "src/dataLayer/apis/CampaignAPI"
import * as CognitoAPI from "src/dataLayer/apis/CognitoAPI"

type OwnProps = {
  children?: never
  tweetIds?: string
}
/**
 * クリエイティブ番号を確認するためのボタンコンポーネント
 */
export const CreativeCheckButton: React.FC<OwnProps> = ({ tweetIds }) => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [tempMessage, setTempMessage] = useState("")

  const onSnackbarClose = (): void => {
    setIsSnackBarOpen(false)
    setTempMessage("")
  }

  const checkCreativeIds = async (): Promise<void> => {
    if (tweetIds == null || tweetIds.trim() === "") {
      return
    }

    try {
      const resp = await fetchCreativeIds(
        await CognitoAPI.fetchOrGetIdToken(),
        { tweetIds: [tweetIds.replace(/;/g, ",")] }
      )
      setIsSnackBarOpen(true)
      const { existIds, notExistIds } = resp
      if (existIds.length > 0 && notExistIds.length === 0) {
        setTempMessage("クリエイティブ番号が確認出来ました")
        return
      }
      setTempMessage(`${notExistIds}のクリエイティブ番号が確認出来ませんでした`)
    } catch (error) {
      console.error(error)
      alert(
        "クリエイティブ情報の取得に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
    }
  }
  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={checkCreativeIds}>
        IDを確認
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isSnackBarOpen}
        message={tempMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Fragment>
  )
}
