/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import MenuItem from "@material-ui/core/MenuItem"
import { useFormikContext } from "formik"
import React from "react"
import { FastTextField } from "src/components/atoms/FastTextField"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const Retargeting: React.FC<OwnProps> = ({ index, disabled }) => {
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()

  return (
    <MultipleFieldRow label="リターゲティング設定">
      <FastTextField
        initialValue={
          values.adGroups[index].others.retargetingType != null
            ? values.adGroups[index].others.retargetingType
            : ""
        }
        onSetValue={(value) => {
          setFieldValue(`adGroups.${index}.others.retargetingType`, value)
          if (value === "Tweet_Impression") {
            setFieldValue(
              `adGroups.${index}.others.retargetingEngagementType`,
              // 「ツイートの表示歴があるユーザー」を選択した場合、
              // 選択出来るエンゲージメントタイプはインプレッションのみのため、強制的に選択する
              "IMPRESSION"
            )
          }
          if (value === "") {
            // リターゲティングを後から無効にした場合、全ての値をリセット
            setFieldValue(
              `adGroups.${index}.others.retargetingEngagementType`,
              ""
            )
            setFieldValue(`adGroups.${index}.others.tweetType`, "")
            setFieldValue(`adGroups.${index}.others.campaignRetargeting`, "")
          }
        }}
        name={`adGroups.${index}.others.retargetingType`}
        variant="outlined"
        size="small"
        label="リターゲティングタイプ"
        select
        css={inlineSelect}
        disabled={disabled}
        // error={!!errorMsg}
        // helperText={errorMsg}
      >
        <MenuItem value="">-</MenuItem>
        <MenuItem value="Tweet_Impression">
          ツイートの表示歴があるユーザー
        </MenuItem>
        <MenuItem value="Tweet_Impression_and_Engagement">
          ツイートの表示歴およびエンゲージメント歴があるユーザー
        </MenuItem>
      </FastTextField>
      {/* 以下はインプレッションは選択し終わっているがUI上で見せるため */}
      {values.adGroups[index].others.retargetingType === "Tweet_Impression" && (
        <FastTextField
          initialValue="IMPRESSION"
          onSetValue={(value) => {
            setFieldValue(
              `adGroups.${index}.others.retargetingEngagementType`,
              value
            )
            if (value === "") {
              setFieldValue(`adGroups.${index}.others.tweetType`, "")
              setFieldValue(`adGroups.${index}.others.campaignRetargeting`, "")
            }
          }}
          name={`adGroups.${index}.others.retargetingEngagementType`}
          variant="outlined"
          size="small"
          label="エンゲージメントタイプ"
          select
          css={inlineSelect}
          disabled={disabled}
          // error={!!errorMsg}
          // helperText={errorMsg}
        >
          <MenuItem value="IMPRESSION">インプレッション</MenuItem>
        </FastTextField>
      )}
      {values.adGroups[index].others.retargetingType ===
        "Tweet_Impression_and_Engagement" && (
        <FastTextField
          initialValue={
            values.adGroups[index].others.retargetingEngagementType != null
              ? values.adGroups[index].others.retargetingEngagementType
              : ""
          }
          onSetValue={(value) => {
            setFieldValue(
              `adGroups.${index}.others.retargetingEngagementType`,
              value
            )
            if (value === "") {
              setFieldValue(`adGroups.${index}.others.tweetType`, "")
              setFieldValue(`adGroups.${index}.others.campaignRetargeting`, "")
            }
          }}
          name={`adGroups.${index}.others.retargetingEngagementType`}
          variant="outlined"
          size="small"
          label="エンゲージメントタイプ"
          select
          css={inlineSelect}
          disabled={disabled}
          // error={!!errorMsg}
          // helperText={errorMsg}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="ENGAGEMENT">すべてのエンゲージメント</MenuItem>
          <MenuItem value="VIDEO_VIEW">動画の再生数</MenuItem>
          <MenuItem value="VIDEO_PLAYBACK_PARTIAL">50％動画再生完了</MenuItem>
          <MenuItem value="VIDEO_PLAYBACK_COMPLETE">動画再生完了</MenuItem>
        </FastTextField>
      )}
      {values.adGroups[index].others.retargetingEngagementType !== "" && (
        <FastTextField
          initialValue={
            values.adGroups[index].others.tweetType != null
              ? values.adGroups[index].others.tweetType
              : ""
          }
          onSetValue={(value) => {
            setFieldValue(`adGroups.${index}.others.tweetType`, value)
            if (value === "") {
              setFieldValue(`adGroups.${index}.others.campaignRetargeting`, "")
            }
          }}
          name={`adGroups.${index}.others.tweetType`}
          variant="outlined"
          size="small"
          label="ツイートの種類"
          select
          css={inlineSelect}
          disabled={disabled}
          // error={!!errorMsg}
          // helperText={errorMsg}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="Organic">オーガニックツイート</MenuItem>
          <MenuItem value="Campaign">
            特定のキャンペーンに含まれたツイート
          </MenuItem>
          <MenuItem value="OrganicAndCampaign">
            オーガニックツイートおよび特定のキャンペーンに含まれたツイート
          </MenuItem>
        </FastTextField>
      )}
      {(values.adGroups[index].others.tweetType === "Campaign" ||
        values.adGroups[index].others.tweetType === "OrganicAndCampaign") && (
        <FastTextField
          initialValue={values.adGroups[index].others.campaignRetargeting}
          onSetValue={(value) =>
            setFieldValue(`adGroups.${index}.others.campaignRetargeting`, value)
          }
          name={`adGroups.${index}.others.campaignRetargeting`}
          variant="outlined"
          size="small"
          css={inlineSelect}
          label="キャンペーンを指定"
          disabled={disabled}
          // error={!!errorMsg}
          // helperText={errorMsg}
        />
      )}
    </MultipleFieldRow>
  )
}

const inlineSelect = css`
  min-width: 240px;
  margin-top: 8px;
  margin-right: 8px;
`
