/** @jsx jsx */
import { jsx } from "@emotion/core"
import Typography from "@material-ui/core/Typography"
import React, { Fragment } from "react"
import { ConfirmArea } from "src/components/organisms/ConfirmArea"
import { CampaignDetail } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  campaignDetail: CampaignDetail
  children?: never
}

export const Confirm: React.FC<OwnProps> = ({ campaignDetail }) => {
  return (
    <Fragment>
      <Typography variant="h5" component="h1">
        入稿依頼編集 確認画面
      </Typography>
      <ConfirmArea campaignDetail={campaignDetail} />
    </Fragment>
  )
}
