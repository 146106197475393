/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { getIn, useFormikContext } from "formik"
import React, { Fragment } from "react"
import { FastTextField } from "src/components/atoms/FastTextField"
import { LabeledCheckbox } from "src/components/molecules/LabeledCheckbox"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { CreativeCheckButton } from "src/components/organisms/CreativeCheckButton"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const CreativeDetail: React.FC<OwnProps> = ({ index, disabled }) => {
  const { setFieldValue, values, errors } = useFormikContext<CommonFormValues>()

  return (
    <Fragment>
      <MultipleFieldRow label="クリエイティブ番号">
        <div css={creativeIds}>
          <FastTextField
            initialValue={values.adGroups[index].others.tweetIds}
            onSetValue={(value) =>
              setFieldValue(`adGroups.${index}.others.tweetIds`, value)
            }
            name={`adGroups.${index}.others.tweetIds`}
            fullWidth
            variant="outlined"
            size="small"
            placeholder="セミコロン( ; )区切りで入力してください"
            disabled={disabled}
            error={!!getIn(errors, `adGroups.${index}.others.tweetIds`)}
            helperText={getIn(errors, `adGroups.${index}.others.tweetIds`)}
          />
          <CreativeCheckButton
            tweetIds={values.adGroups[index].others.tweetIds}
          />
        </div>
      </MultipleFieldRow>
      <TextFieldRow
        label="クリエイティブ指定"
        initialValue={values.adGroups[index].others.mediaCreativeIds}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.others.mediaCreativeIds`, value)
        }
        fieldName={`adGroups.${index}.others.mediaCreativeIds`}
        placeholder="セミコロン( ; )区切りで入力してください"
        disabled={disabled}
      />
      <MultipleFieldRow label="ツイート表示場所">
        <LabeledCheckbox
          checked={values.adGroups[index].adGroupPlacementValues.TIMELINES}
          label="タイムライン"
          onSetValue={(value) => {
            setFieldValue(
              `adGroups.${index}.adGroupPlacementValues.TIMELINES`,
              value
            )
          }}
          disabled={
            disabled ||
            // 特定のキャンペーンの目的を選択、または目的が未選択の場合、タイムラインは選択必須
            [
              "",
              "AWARENESS",
              "FOLLOWERS",
              "PROMOTED_VIDEO_VIEWS",
              "TWEET_ENGAGEMENTS",
              "WEBSITE_CLICKS",
              "IN_STREAM_VIDEO_AD_VIEWS_PREROLL",
            ].some((v) => v === values.campaign.campaignObjective)
          }
        />
        <LabeledCheckbox
          checked={values.adGroups[index].adGroupPlacementValues.SEARCH_RESULTS}
          label="検索結果"
          onSetValue={(value) => {
            setFieldValue(
              `adGroups.${index}.adGroupPlacementValues.SEARCH_RESULTS`,
              value
            )
          }}
          disabled={
            disabled ||
            values.campaign.campaignObjective === "FOLLOWERS" ||
            values.campaign.campaignObjective ===
              "IN_STREAM_VIDEO_AD_VIEWS_PREROLL"
          }
        />
        <LabeledCheckbox
          checked={values.adGroups[index].adGroupPlacementValues.PROFILES}
          label="Twitterプロフィールとツイートの詳細"
          onSetValue={(value) => {
            setFieldValue(
              `adGroups.${index}.adGroupPlacementValues.PROFILES`,
              value
            )
          }}
          disabled={
            disabled ||
            values.campaign.campaignObjective ===
              "IN_STREAM_VIDEO_AD_VIEWS_PREROLL"
          }
        />
      </MultipleFieldRow>
    </Fragment>
  )
}

const creativeIds = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > button {
    white-space: nowrap;
    margin-left: 8px;
  }
`
