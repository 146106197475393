import { I18n } from "aws-amplify"
import {
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  withAuthenticator,
} from "aws-amplify-react"
import React, { Component, FC } from "react"
import { FixMeAny } from "src/types/Utils"

/**
 * 表示する文言の変更
 */
const LANGUAGE_DICT = {
  ja: {
    "Sign in to your account": "ログイン",
    "Sign In Account": "ログイン",
    Username: "ユーザー名",
    "Enter your username": "ユーザー名を入力してください",
    Password: "パスワード",
    "Enter your password": "パスワードを入力してください",
    "Forget your password? ": "パスワードを忘れてしまった方は",
    "Reset password": "こちら",
    "Sign In": "ログイン",
    "Back to Sign In": "ログイン画面へ戻る",
    "Reset your password": "パスワード再設定",
    "Send Code": "送信する",
    Code: "パスワード再設定コード",
    "New Password": "新しいパスワード",
    "Family Name": "姓",
    "Given Name": "名",
    "Resend Code": "コードを再送する",
    Submit: "送信する",
    "Change Password": "パスワード変更",
    Change: "送信する",
    "Account recovery requires verified contact information":
      "アカウントの確認のため、ご登録の連絡先に届いた「検証コード」を入力してください",
    Email: "メールアドレス",
    Skip: "後で確認する",
    Verify: "送信する",

    // エラーメッセージ
    "Username cannot be empty": "ユーザー名を入力してください。",
    "null invocation failed due to configuration.":
      "パスワードを入力してください。",
    "Incorrect username or password":
      "ユーザー名またはパスワードに誤りがあります。",
    "User does not exist": "入力したユーザー名が見つかりませんでした。",
    "Username/client id combination not found.":
      "入力したユーザー名が見つかりませんでした。",
    "User is disabled": "入力したユーザーのステータスは無効になっています。",
    "Invalid verification code provided, please try again.":
      "無効な確認コードが指定されました。もう一度お試しください。",
    "Password cannot be empty": "パスワードを入力してください。",
    "Confirmation code cannot be empty": "確認コードを入力してください。",
    "Attempt limit exceeded, please try after some time.":
      "パスワード再設定の条件回数を超えました。しばらくしてから再度お試しください。",
  },
}

I18n.putVocabularies(LANGUAGE_DICT)

const renderComponents = [
  <SignIn key="SignIn" />,
  <ConfirmSignIn key="ConfirmSignIn" />,
  <RequireNewPassword key="RequireNewPassword" />,
  <ForgotPassword key="ForgotPassword" />,
]

const MyTheme = {
  button: {
    fontSize: "1rem",
    borderRadius: ".25rem",
    padding: "0.55rem",
    backgroundColor: "#00ABCB",
  },
  a: { color: "#00ABCB" },
  toast: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
  },
  formContainer: { marginTop: 0 },
  formRow: {
    display: "flex",
    alignItems: "center",
  },
  label: { marginBottom: 0 },
}

export const withMyAuthenticator = (
  Comp: Component | FC,
  includeGreetings = false
): FixMeAny => {
  return withAuthenticator(
    Comp,
    includeGreetings,
    renderComponents,
    undefined,
    MyTheme
  )
}
