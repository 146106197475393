/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { saveAs } from "file-saver"
import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { OrderDetailMetaInfo } from "src/components/atoms/OrderDetailMetaInfo"
import { ConfirmArea } from "src/components/organisms/ConfirmArea"
import * as CampaignAPI from "src/dataLayer/apis/CampaignAPI"
import * as CognitoAPI from "src/dataLayer/apis/CognitoAPI"
import {
  CampaignDetail,
  InhouseCampaignId,
} from "src/domainLayer/models/CampaignInfo"
import { campaignOperations } from "src/ducks/campaign"
import { settingsSelectors } from "src/ducks/settings"

type OwnProps = {
  campaignDetail: CampaignDetail
  children?: never
}

/**
 * bulk_sheetをexcelとしてダウンロード
 */
const dlBulkSheet = async (
  inhouseCampaignId: InhouseCampaignId,
  campaignName: string
): Promise<void> => {
  const resp = await CampaignAPI.fetchBulkSheet(
    await CognitoAPI.fetchOrGetIdToken(),
    {
      inhouse_campaign_id: inhouseCampaignId,
    }
  )
  const blobBulk = new Blob([resp], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  saveAs(blobBulk, `bulk_sheet-${campaignName}-${inhouseCampaignId}.xlsx`)
}

export const ReadinessPublish: React.FC<OwnProps> = ({ campaignDetail }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userRole = useSelector(settingsSelectors.getUserRoleStrict)
  const {
    inhouseCampaignId,
    inhouseOrderCreatedBy,
    inhouseOrderCreatedAt,
    inhouseOrderUpdatedBy,
    inhouseOrderUpdatedAt,
    campaignName,
  } = campaignDetail.campaign

  return (
    <Fragment>
      <Typography variant="h5" component="h1">
        出稿準備完了
      </Typography>
      <OrderDetailMetaInfo
        inhouseOrderCreatedBy={inhouseOrderCreatedBy}
        inhouseOrderCreatedAt={inhouseOrderCreatedAt}
        inhouseOrderUpdatedBy={inhouseOrderUpdatedBy}
        inhouseOrderUpdatedAt={inhouseOrderUpdatedAt}
      />
      <ConfirmArea campaignDetail={campaignDetail} />
      <div css={buttonGroup}>
        {userRole === "operator" && (
          <Fragment>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={() => {
                dispatch(
                  campaignOperations.updateStatus(
                    {
                      inhouse_campaign_id: inhouseCampaignId,
                      adGroups: campaignDetail.adGroups.map((ag) => {
                        return {
                          id: ag.inhouseAdGroupId,
                          sendBackFlag: false,
                          status: 4,
                        }
                      }),
                      campaign: {
                        id: inhouseCampaignId,
                        sendBackFlag: false,
                        status: 4,
                      },
                    },
                    () => history.push("/")
                  )
                )
              }}
            >
              下書き状態に戻す
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                dlBulkSheet(
                  inhouseCampaignId,
                  // ありえないが、「完了の入稿」の型を定義できてない（「下書き中の入稿」と型がおなじため）、undefined able
                  campaignName || "(未設定)"
                )
              }}
            >
              バルクシート生成
            </Button>
          </Fragment>
        )}
        {/* {userRole === "sales" && (
          <Button
            color="inherit"
            variant="contained"
            size="large"
            component={Link}
            to="/orders"
          >
            複製する
          </Button>
        )} */}
      </div>
    </Fragment>
  )
}

const buttonGroup = css`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`
