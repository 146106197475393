import { getIn, useField, useFormikContext } from "formik"
import React, { Fragment } from "react"
import { AccordionPanelRow } from "src/components/molecules/AccordionPanelRow"
import { DateTimePickerRow } from "src/components/molecules/DateTimePickerRow"
import { NumberFieldRow } from "src/components/molecules/NumberFieldRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { BidDetail } from "src/components/organisms/AdsGroupInfoForm/BidDetail"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"
import { formatDateAsAPI } from "src/utils/DateTimeUtils"

type OwnProps = {
  children?: never
  disabled?: boolean
  index: number
}

export const AdGroupDetail: React.FC<OwnProps> = ({ index, disabled }) => {
  const { setFieldValue, values, errors } = useFormikContext<CommonFormValues>()
  const [adGroupStartTimeField] = useField<Date | null>(
    `adGroups.${index}.adGroupStartTime`
  )
  const [adGroupEndTimeField] = useField<Date | null>(
    `adGroups.${index}.adGroupEndTime`
  )

  return (
    <Fragment>
      <TextFieldRow
        label="広告グループ名"
        initialValue={values.adGroups[index].adGroupName}
        onSetValue={(value) =>
          setFieldValue(`adGroups.${index}.adGroupName`, value)
        }
        fieldName={`adGroups.${index}.adGroupName`}
        disabled={disabled}
        errorMsg={getIn(errors, `adGroups.${index}.adGroupName`)}
      />
      <AccordionPanelRow
        label={`開始日時 ${
          values.adGroups[index].adGroupStartTime != null
            ? formatDateAsAPI(values.adGroups[index].adGroupStartTime)
            : "未設定"
        } / 終了日時 ${
          values.adGroups[index].adGroupEndTime != null
            ? formatDateAsAPI(values.adGroups[index].adGroupEndTime)
            : "未設定"
        } / 広告グループ予算 ${
          values.adGroups[index].adGroupTotalBudget != null
            ? values.adGroups[index].adGroupTotalBudget
            : "未設定"
        }`}
      >
        <DateTimePickerRow
          label="広告グループ 開始日時"
          field={adGroupStartTimeField}
          onSetValue={(value) => {
            setFieldValue(`adGroups.${index}.adGroupStartTime`, value)
          }}
          disabled={disabled}
        />
        <DateTimePickerRow
          label="広告グループ 終了日時"
          field={adGroupEndTimeField}
          onSetValue={(value) => {
            setFieldValue(`adGroups.${index}.adGroupEndTime`, value)
          }}
          disabled={disabled}
        />
        <NumberFieldRow
          label="広告グループ予算"
          initialValue={values.adGroups[index].adGroupTotalBudget}
          onSetValue={(value) =>
            setFieldValue(`adGroups.${index}.adGroupTotalBudget`, value)
          }
          fieldName={`adGroups.${index}.adGroupTotalBudget`}
          disabled={disabled}
        />
      </AccordionPanelRow>
      <BidDetail
        campaignName="campaign.campaignObjective"
        index={index}
        disabled={disabled}
      />
      <TextFieldRow
        label="DoubleClickトラッキング"
        initialValue={values.adGroups[index].others.trackingWithDoubleclick}
        onSetValue={(value) =>
          setFieldValue(
            `adGroups.${index}.others.trackingWithDoubleclick`,
            value
          )
        }
        fieldName={`adGroups.${index}.others.trackingWithDoubleclick`}
        placeholder="https://"
        disabled={disabled}
      />
    </Fragment>
  )
}
