/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React, { ReactNode } from "react"

type OwnProps = {
  label: string | ReactNode
}

/**
 * アコーディオンをラップするコンポーネント
 */
export const AccordionPanelRow: React.FC<OwnProps> = ({ label, children }) => {
  return (
    <TableRow>
      <TableCell colSpan={2} css={accordionCell}>
        <ExpansionPanel elevation={0} css={accordionCellPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            css={accordionCellTitle}
          >
            {label}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails css={accordionCellContent}>
            <Table>
              <TableBody>{children}</TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </TableCell>
    </TableRow>
  )
}

const accordionCell = css`
  padding: 0;
`
const accordionCellPanel = css`
  border-radius: 0;
  &:before {
    display: none;
  }
`
const accordionCellContent = css`
  padding: 0 0 0 40px;
`
const accordionCellTitle = css`
  padding-left: 16px;
`
