import { InputProps, TextField, TextFieldProps } from "@material-ui/core"
import React, { useEffect, useState } from "react"

type OwnProps = {
  children?: never
  initialValue: number | undefined
  onSetValue: (value: number | undefined) => void
} & TextFieldProps

/**
 * number 型のテキストフィールド用コンポーネント
 */
export const FastNumberField: React.FC<OwnProps> = ({
  initialValue,
  onSetValue,
  ...rest
}) => {
  // We need to keep and update the state of the cell normally
  const [tempValue, setTempValue] = useState(initialValue)

  const onChange: InputProps["onChange"] = (e) => {
    setTempValue(Number(e.target.value))
  }

  // We'll only update the external data when the input is blurred
  const _onBlur: InputProps["onBlur"] = () => {
    onSetValue(tempValue)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setTempValue(initialValue)
  }, [initialValue])

  return (
    <TextField
      {...rest}
      onChange={onChange}
      onBlur={_onBlur}
      value={tempValue}
      type="number"
    />
  )
}
