import * as CognitoAPI from "src/dataLayer/apis/CognitoAPI"
import * as TwitterAPI from "src/dataLayer/apis/TwitterAPI"
import { AccountId, PublishedCampaign } from "src/domainLayer/models/TwitterAPI"

type ReturnValues = {
  fetchPublishedCampaignList: (queryParams: {
    accountId: AccountId
  }) => Promise<PublishedCampaign[]>
}

/**
 * redux で管理すべき API とは質が異なるため、Hooks にしている
 */
export const useFetchPublishedCampaignList = (): ReturnValues => {
  return {
    fetchPublishedCampaignList: async (queryParams) => {
      let result

      try {
        result = await TwitterAPI.fetchCampaignList(
          await CognitoAPI.fetchOrGetIdToken(),
          { account_id: queryParams.accountId }
        )
      } catch (error) {
        console.error(error)
        alert(
          "入稿の継続キャンペーンリストの取得に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
        )
        return []
      }

      return result.data.map((c) =>
        Object.assign(c, { accountId: queryParams.accountId })
      )
    },
  }
}
