import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import React from "react"

type OwnProps = {
  children?: never
  disabled?: boolean
  label: string
  value: string
}

/**
 * ラジオボタン用コンポーネント
 */
export const RadioField: React.FC<OwnProps> = ({ label, value, disabled }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label}
      disabled={disabled}
    />
  )
}
