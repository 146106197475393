/** @jsx jsx */
import { css, jsx } from "@emotion/core"

type OwnProps = {
  children?: never
  inhouseOrderCreatedAt: string
  inhouseOrderCreatedBy: string
  inhouseOrderUpdatedAt: string | undefined
  inhouseOrderUpdatedBy: string | undefined
}

export const OrderDetailMetaInfo: React.FC<OwnProps> = ({
  inhouseOrderCreatedBy,
  inhouseOrderCreatedAt,
  inhouseOrderUpdatedBy,
  inhouseOrderUpdatedAt,
}) => {
  return (
    <div>
      <ul css={inhouseOrderDetail}>
        <li>登録者 {inhouseOrderCreatedBy}</li>
        <li>登録日 {inhouseOrderCreatedAt}</li>
      </ul>
      {inhouseOrderUpdatedBy && (
        <ul css={inhouseOrderDetail}>
          <li>更新者 {inhouseOrderUpdatedBy}</li>
          <li>更新日 {inhouseOrderUpdatedAt}</li>
        </ul>
      )}
    </div>
  )
}
const inhouseOrderDetail = css`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  li {
    display: inline-block;
    margin-right: 8px;
  }
`
