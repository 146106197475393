import axios from "axios"
import qs from "qs"
import { INHOUSE_ADS_API_PATH } from "src/constants/Env"
import { FetchCampaignListResp } from "src/dataLayer/apis/CampaignAPI/types/FetchCampaignListResp"
import {
  FetchCampaignResp,
  FetchPublishedCampaignResp,
} from "src/dataLayer/apis/CampaignAPI/types/FetchCampaignResp"
import {
  FetchCreativeIdsReq,
  FetchCreativeIdsResp,
} from "src/dataLayer/apis/CampaignAPI/types/FetchCreativeIdsResp"
import { PostCampaignReq } from "src/dataLayer/apis/CampaignAPI/types/PostCampaignReq"
import { PutCampaignAndAdGroupsSendBackStatusReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignAndAdGroupsSendBackStatusReq"
import { PutCampaignAndAdGroupsStatusReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignAndAdGroupsStatusReq"
import { PutCampaignReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignReq"
import { AuthorizationToken } from "src/dataLayer/apis/types"
import {
  CampaignId,
  InhouseCampaignId,
} from "src/domainLayer/models/CampaignInfo"
import { AccountId } from "src/domainLayer/models/TwitterAPI"

/**
 * キャンペーン、及び、キャンペーン全体（キャンペーン＋広告）に関する API
 */

/**
 * （特別な理由がなければ）共通で使う URL パラメータ
 */
export type UrlParams = {
  inhouse_campaign_id: InhouseCampaignId
}

/**
 * Twitter API で発行されるキャンペーンIDに紐付く情報を取得する際に使う URL パラメータ
 */
export type PublishedUrlParams = {
  account_id: AccountId
  campaign_id: CampaignId
}

/**
 * キャンペーンの一覧を取得
 * GET /documents/list
 * @param authToken 認証トークン
 * @param urlParams URL中のパラメータ
 * @param queryStrings GETパラメータ
 * @param requestBody POSTパラメータ
 */
export const fetchCampaignList = async (
  authToken: AuthorizationToken
): Promise<FetchCampaignListResp> => {
  const resp = await axios.get<FetchCampaignListResp>(
    `${INHOUSE_ADS_API_PATH}/documents/list`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}

/**
 * キャンペーンの詳細情報を取得
 * GET /documents/details/{inhouse_campaign_id}
 */
export const fetchCampaign = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams
): Promise<FetchCampaignResp> => {
  const resp = await axios.get<FetchCampaignResp>(
    `${INHOUSE_ADS_API_PATH}/documents/details/${urlParams.inhouse_campaign_id}`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}

/**
 * キャンペーンの新規作成
 * POST /documents/insert
 */
export const postCampaign = async (
  authToken: AuthorizationToken,
  requestBody: PostCampaignReq
): Promise<InhouseCampaignId> => {
  const resp = await axios.post<InhouseCampaignId>(
    `${INHOUSE_ADS_API_PATH}/documents/insert`,
    requestBody,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}

/**
 * キャンペーンの更新
 * PUT /documents/update/{inhouse_campaign_id}
 */
export const putCampaign = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams,
  requestBody: PutCampaignReq
): Promise<void> => {
  await axios.put<void>(
    `${INHOUSE_ADS_API_PATH}/documents/update/${urlParams.inhouse_campaign_id}`,
    requestBody,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
}

/**
 * キャンペーンの削除
 * POST /documents/delete/{inhouse_campaign_id}
 */
export const deleteCampaign = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams
): Promise<void> => {
  await axios.delete<void>(
    `${INHOUSE_ADS_API_PATH}/documents/delete/${urlParams.inhouse_campaign_id}`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
}

/**
 * キャンペーンとその配下の広告のステータス更新
 * PUT /documents/update/status/{inhouse_campaign_id}
 */
export const putCampaignAndAdGroupsStatus = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams,
  requestBody: PutCampaignAndAdGroupsStatusReq
): Promise<void> => {
  await axios.put<void>(
    `${INHOUSE_ADS_API_PATH}/documents/update/status/${urlParams.inhouse_campaign_id}`,
    requestBody,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
}
/**
 * キャンペーンとその配下の広告の差し戻しステータス更新
 * PUT /documents/update/status/send_back/{inhouse_campaign_id}
 */
export const putCampaignAndAdGroupsSendBackStatus = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams,
  requestBody: PutCampaignAndAdGroupsSendBackStatusReq
): Promise<void> => {
  await axios.put<void>(
    `${INHOUSE_ADS_API_PATH}/documents/update/status/${urlParams.inhouse_campaign_id}`,
    requestBody,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
}

/**
 * 継続したいキャンペーンの詳細情報を取得
 * GET /accounts/{account_id}/campaigns/{campaign_id}
 */
export const fetchPublishedCampaign = async (
  authToken: AuthorizationToken,
  publishedUrlParams: PublishedUrlParams
): Promise<FetchPublishedCampaignResp> => {
  const resp = await axios.get<FetchCampaignResp>(
    `${INHOUSE_ADS_API_PATH}/accounts/${publishedUrlParams.account_id}/campaigns/${publishedUrlParams.campaign_id}`,
    {
      headers: {
        Authorization: authToken,
      },
    }
  )
  return resp.data
}

/**
 * バルクシートを取得
 * GET /documents/bulk_sheet/{inhouse_campaign_id}
 */
export const fetchBulkSheet = async (
  authToken: AuthorizationToken,
  urlParams: UrlParams
): Promise<ArrayBuffer> => {
  const resp = await axios.get<ArrayBuffer>(
    `${INHOUSE_ADS_API_PATH}/documents/bulk_sheet/${urlParams.inhouse_campaign_id}`,
    {
      headers: {
        Authorization: authToken,
      },
      responseType: "arraybuffer",
    }
  )
  return resp.data
}

/**
 * クリエイティブIDの存在を確認
 * GET /creative/check
 */
export const fetchCreativeIds = async (
  authToken: AuthorizationToken,
  params: FetchCreativeIdsReq
): Promise<FetchCreativeIdsResp> => {
  const resp = await axios.get<FetchCreativeIdsResp>(
    `${INHOUSE_ADS_API_PATH}/creative/check`,
    {
      headers: {
        Authorization: authToken,
      },
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "comma" }),
    }
  )
  return resp.data
}
