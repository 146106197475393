import * as operations from "./operations"
import { State, reducers } from "./reducers"
import * as selectors from "./selectors"

/**
 * キャンペーン全体（＝入稿）（＝キャンペーン + 広告グループ）に関する state
 */

export const campaignOperations = operations
export const campaignReducer = reducers
export const campaignSelectors = selectors

export type CampaignState = State
