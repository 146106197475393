import { array, boolean, number, object, string } from "yup"

export const commonValidationSchema = object({
  submitAsComplete: boolean(),
  campaign: object().when("submitAsComplete", {
    is: true,
    then: object().shape({
      clientName: string()
        .required("必須です")
        .typeError("必須です"),
      twitterAccountName: string()
        .required("必須です")
        .typeError("必須です"),
      grossBudget: number()
        .required("必須です")
        .typeError("必須です")
        .integer("整数値を入力してください"),
      campaignObjective: string()
        .required("必須です")
        .typeError("必須です"),
      campaignName: string()
        .required("必須です")
        .typeError("必須です"),
      campaignDailyBudget: number()
        .required("必須です")
        .typeError("必須です")
        .integer("整数値を入力してください"),
      campaignTotalBudget: number()
        .required("必須です")
        .typeError("必須です")
        .integer("整数値を入力してください"),
      campaignStartDate: string()
        .required("必須です")
        .typeError("必須です"),
      campaignEndDate: string()
        .required("必須です")
        .typeError("必須です"),
      delivery: string()
        .required("必須です")
        .typeError("必須です"),
    }),
  }),
  adGroups: array().when("submitAsComplete", {
    is: true,
    then: array().of(
      object().shape({
        adGroupName: string()
          .required("必須です")
          .typeError("必須です"),
        others: object().shape({
          bidType: string()
            .required("必須です")
            .typeError("必須です"),
          bidAmount: number().when("bidType", (value: string) => {
            if (value === "AUTO_BID") {
              return number()
            } else {
              // 自動入札以外は入札額を入力しなければならない
              return number()
                .required("必須です")
                .typeError("必須です")
                .integer("整数値を入力してください")
                .min(1, "1以上の整数を入力してください")
            }
          }),
          language: string()
            .required("必須です")
            .typeError("必須です"),
          location: string()
            .required("必須です")
            .typeError("必須です"),
          tweetIds: string()
            .required("必須です")
            .typeError("必須です"),
        }),
      })
    ),
  }),
})
