/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import {
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Typography,
} from "@material-ui/core"
import { Formik } from "formik"
import React, { Dispatch, Fragment, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { OrderDetailMetaInfo } from "src/components/atoms/OrderDetailMetaInfo"
import { convertAPIToForm } from "src/components/helpers/FormUtils/utils"
import { commonValidationSchema } from "src/components/pages/EditOrder/form/validations/commonValidationSchema"
import { ClientCampaignInfo } from "src/components/pages/EditRequestOrderDetail/ClientCampaignInfo"
import { EditAdsGroupsInfo } from "src/components/pages/EditRequestOrderDetail/EditAdsGroupInfo"
import { localInitialValues } from "src/components/pages/EditRequestOrderDetail/form/types/CommonFormValues"
import { RoutePath } from "src/constants/Paths"
import { createSendBackCampaignAndAdGroupsReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignAndAdGroupsStatusReq"
import {
  InhouseAdGroupId,
  assertIsInhouseAdGroupId,
} from "src/domainLayer/models/AdsGroupInfo"
import {
  CampaignDetail,
  InhouseCampaignId,
} from "src/domainLayer/models/CampaignInfo"
import { campaignOperations } from "src/ducks/campaign"
import { AppThunkAction } from "src/types/ReduxTypes"

type OwnProps = {
  campaignDetail: CampaignDetail
  children?: never
}

const updateToEditStatus = async (
  campaignDetail: CampaignDetail,
  dispatch: Dispatch<AppThunkAction>
): Promise<void> => {
  await dispatch(
    campaignOperations.updateStatus(
      {
        inhouse_campaign_id: campaignDetail.campaign.inhouseCampaignId,
        adGroups: campaignDetail.adGroups.map((a) => {
          assertIsInhouseAdGroupId(a.inhouseAdGroupId)
          return {
            id: a.inhouseAdGroupId,
            sendBackFlag: false,
            status: 4,
          }
        }),
        campaign: {
          id: campaignDetail.campaign.inhouseCampaignId,
          sendBackFlag: false,
          status: 4,
        },
      },
      // ステータスを運用編集中に変更した後、現時点での最新データをfetchする
      () =>
        dispatch(
          campaignOperations.fetchCampaignDetail(
            campaignDetail.campaign.inhouseCampaignId
          )
        )
    )
  )
}

/**
 * 運用入稿依頼確認 兼 編集画面
 */
export const EditRequestOrderDetail: React.FC<OwnProps> = ({
  campaignDetail,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [
    sendBackCampaignId,
    setSendBackCampaignId,
  ] = useState<InhouseCampaignId | null>(null)
  const [sendBackAdGroupIds, setSendBackAdGroupIds] = useState<
    InhouseAdGroupId[]
  >([])

  /**
   * 差し戻し対象の広告グループIDを取得
   */
  const selectSendBackAdGroup = (
    e: React.ChangeEvent<HTMLInputElement>,
    inhouseAdGroupId: InhouseAdGroupId
  ): void => {
    if (e.target.checked) {
      setSendBackAdGroupIds([...sendBackAdGroupIds, inhouseAdGroupId])
    } else {
      const i = sendBackAdGroupIds.indexOf(inhouseAdGroupId)
      sendBackAdGroupIds.splice(i, 1)
      setSendBackAdGroupIds(sendBackAdGroupIds)
    }
  }

  /**
   * キャンペーンと広告グループを一括差し戻し
   */
  const sendBackCampaign = async (): Promise<void> => {
    if (campaignDetail == null) {
      return
    }

    await dispatch(
      campaignOperations.updateStatus(
        {
          inhouse_campaign_id: campaignDetail.campaign.inhouseCampaignId,
          ...createSendBackCampaignAndAdGroupsReq({
            sendBackAdGroupIds,
            campaignDetail,
            sendBackCampaignId,
          }),
        },
        () => {
          history.push(RoutePath.ROOT)
        }
      )
    )
  }

  const initialValues = useMemo(() => convertAPIToForm(campaignDetail), [
    campaignDetail,
  ])
  const {
    inhouseOrderCreatedBy,
    inhouseOrderCreatedAt,
    inhouseOrderUpdatedBy,
    inhouseOrderUpdatedAt,
  } = campaignDetail.campaign

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || localInitialValues}
      validationSchema={commonValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        await actions.setFieldValue("submitAsComplete", true)
        const errors = await actions.validateForm()
        actions.setFieldValue("submitAsComplete", false)

        if (Object.keys(errors).length) {
          return
        }

        await dispatch(
          campaignOperations.updateStatus(
            {
              inhouse_campaign_id: values.campaign.inhouseCampaignId,
              adGroups: values.adGroups.map((a) => {
                assertIsInhouseAdGroupId(a.inhouseAdGroupId)
                return {
                  id: a.inhouseAdGroupId,
                  sendBackFlag: false,
                  status: 5,
                }
              }),
              campaign: {
                id: values.campaign.inhouseCampaignId,
                sendBackFlag: false,
                status: 5,
              },
            },
            () => {
              history.push(RoutePath.ROOT)
            }
          )
        )
      }}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" component="h1">
            入稿依頼編集画面
          </Typography>
          <OrderDetailMetaInfo
            inhouseOrderCreatedBy={inhouseOrderCreatedBy}
            inhouseOrderCreatedAt={inhouseOrderCreatedAt}
            inhouseOrderUpdatedBy={inhouseOrderUpdatedBy}
            inhouseOrderUpdatedAt={inhouseOrderUpdatedAt}
          />
          <h3>クライアント/キャンペーン情報</h3>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  if (campaignDetail?.campaign == null) {
                    return
                  }
                  if (e.target.checked) {
                    setSendBackCampaignId(
                      campaignDetail?.campaign.inhouseCampaignId
                    )
                  } else {
                    setSendBackCampaignId(null)
                  }
                }}
                value={campaignDetail?.campaign.inhouseCampaignId}
                color="secondary"
              />
            }
            label="差し戻す"
          />
          <ClientCampaignInfo
            inhouseCampaignStatus={
              campaignDetail?.campaign.inhouseCampaignStatus
            }
          />
          <h3>広告グループ</h3>
          <EditAdsGroupsInfo
            inhouseCampaignStatus={
              campaignDetail?.campaign.inhouseCampaignStatus
            }
            selectSendBackAdGroup={selectSendBackAdGroup}
          />
          <div css={buttonGroup}>
            <Fragment>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={sendBackCampaign}
              >
                一括差し戻し
              </Button>
              {campaignDetail?.campaign.inhouseCampaignStatus === 3 && (
                <Fab
                  color="primary"
                  variant="extended"
                  onClick={() => updateToEditStatus(campaignDetail, dispatch)}
                  disabled={
                    values.campaign.isEdit ||
                    values.adGroups.some((ag) => ag.isEdit === true)
                  }
                  css={receivedButton}
                >
                  編集を開始する
                </Fab>
              )}
              {campaignDetail?.campaign.inhouseCampaignStatus === 4 && (
                <Fab
                  variant="extended"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={
                    values.campaign.isEdit ||
                    values.adGroups.some((ag) => ag.isEdit === true)
                  }
                  css={receivedButton}
                >
                  キャンペーン 完成
                </Fab>
              )}
            </Fragment>
          </div>
        </form>
      )}
    </Formik>
  )
}

const buttonGroup = css`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`

const receivedButton = css`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
`
