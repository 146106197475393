import {
  InhouseAdGroupId,
  InhouseAdGroupStatus,
} from "src/domainLayer/models/AdsGroupInfo"
import {
  CampaignDetail,
  InhouseCampaignId,
  InhouseCampaignStatus,
} from "src/domainLayer/models/CampaignInfo"

export type PutCampaignAndAdGroupsStatusReq = {
  adGroups: PutAdGroupsStatusReq[]
  campaign: PutCampaignStatusReq
}

type PutCampaignStatusReq = {
  id: InhouseCampaignId
  sendBackFlag: boolean
  status: InhouseCampaignStatus
}

type PutAdGroupsStatusReq = {
  id: InhouseAdGroupId
  sendBackFlag: boolean
  status: InhouseAdGroupStatus
}
type PutCampaignAndAdGroupsStatusReqSrc = {
  campaignDetail: CampaignDetail
  sendBackAdGroupIds: InhouseAdGroupId[]
  sendBackCampaignId: InhouseCampaignId | null
}
/**
 * 差し戻す時のパラメーター整形
 * 差し戻し対象の広告グループはステータス 2、かつ差し戻しフラグを立てて返す
 */
export const createSendBackCampaignAndAdGroupsReq = ({
  sendBackAdGroupIds,
  campaignDetail,
  sendBackCampaignId,
}: PutCampaignAndAdGroupsStatusReqSrc): PutCampaignAndAdGroupsStatusReq => {
  const sendBackAdGroupParams = sendBackAdGroupIds.map((id) => {
    return {
      id: id,
      sendBackFlag: true,
      status: 2 as InhouseAdGroupStatus,
    }
  })
  const excludedSendBackAdGroupParams = campaignDetail.adGroups
    .filter((adGroup) => !sendBackAdGroupIds.includes(adGroup.inhouseAdGroupId))
    .map((adGroup) => {
      return {
        id: adGroup.inhouseAdGroupId,
        sendBackFlag: false,
        status: adGroup.inhouseAdGroupStatus,
      }
    })

  const sendBackCampaignParams = {
    id: campaignDetail.campaign.inhouseCampaignId,
    sendBackFlag: !!sendBackCampaignId,
    status: sendBackCampaignId
      ? 2
      : campaignDetail.campaign.inhouseCampaignStatus,
  }
  return {
    campaign: sendBackCampaignParams,
    adGroups: [...sendBackAdGroupParams, ...excludedSendBackAdGroupParams],
  }
}
