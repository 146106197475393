import {
  Account,
  AccountId,
  PublishedCampaign,
} from "src/domainLayer/models/TwitterAPI"
import actionCreatorFactory from "typescript-fsa"

const DOMAIN = "TwitterAPI/"

export const ActionTypes = {
  FETCH_ACCOUNT_LIST: DOMAIN + "FETCH_ACCOUNT_LIST",
  FETCH_PUBLISHED_CAMPAIGN_LIST: DOMAIN + "FETCH_PUBLISHED_CAMPAIGN_LIST",
  REFRESH_PUBLISHED_CAMPAIGN_LIST: DOMAIN + "REFRESH_PUBLISHED_CAMPAIGN_LIST",
}

const create = actionCreatorFactory()

/**
 * TwitterAPIからアカウント情報を取得
 */
export const fetchAccountList = create.async<void, Account[], void>(
  ActionTypes.FETCH_ACCOUNT_LIST
)

/**
 * TwitterAPIからキャンペーン情報を取得
 */
export const fetchPublishedCampaignList = create.async<
  { accountId: AccountId },
  PublishedCampaign[],
  void
>(ActionTypes.FETCH_PUBLISHED_CAMPAIGN_LIST)

/**
 * 継続キャンペーン一覧を全て削除する
 */
export const refreshPublishedCampaignList = create(
  ActionTypes.REFRESH_PUBLISHED_CAMPAIGN_LIST
)
