import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import CodeIcon from "@material-ui/icons/Code"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import HomeIcon from "@material-ui/icons/Home"
import NoteAddIcon from "@material-ui/icons/NoteAdd"
import { Auth } from "aws-amplify"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { RoutePath } from "src/constants/Paths"

const useStyles = makeStyles({
  drawerRoot: {
    width: 280,
  },
  drawerDocked: {
    width: 280,
  },
})

type OwnProps = {
  children?: never
}

export const Sidebar: React.FC<OwnProps> = () => {
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{ classes: { root: classes.drawerRoot } }}
      classes={{ docked: classes.drawerDocked }}
    >
      <Divider />
      <List>
        <ListItem button component={RouterLink} to={RoutePath.ROOT}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="TOP" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component={RouterLink} to={RoutePath.CREATE_ORDER}>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText primary="入稿依頼新規作成" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          component={RouterLink}
          to={RoutePath.PUBLISHED_CAMPAIGNS}
        >
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText primary="実行中のキャンペーン一覧" />
        </ListItem>
      </List>
      <Divider />

      <List>
        <ListItem button component={RouterLink} to={RoutePath.CONNECT_DB_DEV}>
          <ListItemIcon>
            <CodeIcon />
          </ListItemIcon>
          <ListItemText primary="開発用 DB接続エリア" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            primary="ログアウト"
            onClick={() => {
              Auth.signOut()
            }}
          />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  )
}
