/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TableCell,
} from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { BidDetail } from "src/components/molecules/AdGroupConfirmArea/BidDetail"
import { TailoredAudience } from "src/components/molecules/AdGroupConfirmArea/TailoredAudience"
import { AdGroupInner } from "src/domainLayer/models/AdsGroupInfo"
import { CampaignObjective } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  adInfo: AdGroupInner
  campaignObjective?: CampaignObjective
  children?: never
}

export const AdGroupConfirmArea: React.FC<OwnProps> = ({
  adInfo,
  campaignObjective,
}) => {
  return (
    <ExpansionPanelDetails css={adsGroupContent}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              広告グループ名
            </TableCell>
            <TableCell align="left">{adInfo.adGroupName}</TableCell>
          </TableRow>
          {/* (折り畳み) 開始日時 / 終了日時 / 広告グループ予算 */}
          <TableRow>
            <TableCell colSpan={2} css={accordionCell}>
              <ExpansionPanel elevation={0} css={accordionCellPanel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  css={accordionCellTitle}
                >
                  開始日時 / 終了日時 / 広告グループ予算
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={accordionCellContent}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" css={tableTh}>
                          開始日時 / 終了日時
                        </TableCell>
                        <TableCell align="left">
                          {adInfo.adGroupStartTime}
                        </TableCell>
                        <TableCell align="left">~</TableCell>
                        <TableCell align="left">
                          {adInfo.adGroupEndTime}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" css={tableTh}>
                          広告グループ予算
                        </TableCell>
                        <TableCell align="left">
                          {adInfo.adGroupTotalBudget}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </TableCell>
          </TableRow>
          <BidDetail adInfo={adInfo} campaignObjective={campaignObjective} />
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              DoubleClickトラッキング
            </TableCell>
            <TableCell align="left">
              {adInfo.others.trackingWithDoubleclick}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} css={accordionCell}>
              <TailoredAudience adInfo={adInfo} />
            </TableCell>
          </TableRow>
          {/* (折り畳み) 性別 / 年齢 / 言語 / 地域 / 端末 */}
          <TableRow>
            <TableCell colSpan={2} css={accordionCell}>
              <ExpansionPanel
                defaultExpanded
                elevation={0}
                css={accordionCellPanel}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  css={accordionCellTitle}
                >
                  性別 / 年齢 / 言語 / 地域 / 端末
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={accordionCellContent}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" css={tableTh}>
                          性別
                        </TableCell>
                        <TableCell align="left">
                          {adInfo.others.gender}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" css={tableTh}>
                          年齢
                        </TableCell>
                        <TableCell align="left">{adInfo.others.age}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" css={tableTh}>
                          言語
                        </TableCell>
                        <TableCell align="left">
                          {adInfo.others.language.join(",")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" css={tableTh}>
                          地域
                        </TableCell>
                        <TableCell align="left">
                          {adInfo.others.location.join(",")}
                        </TableCell>
                      </TableRow>
                      {/* (折り畳み) 端末 / プラットフォーム */}
                      <TableRow>
                        <TableCell colSpan={2} css={accordionCell}>
                          <ExpansionPanel
                            elevation={0}
                            css={accordionCellPanel}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              css={accordionCellTitle}
                            >
                              端末 / プラットフォーム
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails css={accordionCellContent}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan={2} css={nestedTable}>
                                      <Table>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              css={tableTh}
                                            >
                                              OSバージョン
                                            </TableCell>
                                            <TableCell align="left">
                                              {adInfo.others.userOsVersion?.join(
                                                ","
                                              )}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              css={tableTh}
                                            >
                                              プラットフォーム
                                            </TableCell>
                                            <TableCell align="left">
                                              {adInfo.others.platform?.join(
                                                ","
                                              )}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              css={tableTh}
                                            >
                                              端末モデル
                                            </TableCell>
                                            <TableCell align="left">
                                              {adInfo.others.userDevice?.join(
                                                ","
                                              )}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              css={tableTh}
                                            >
                                              携帯電話会社
                                            </TableCell>
                                            <TableCell align="left">
                                              {adInfo.others.mobileCarriers?.join(
                                                ","
                                              )}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              css={tableTh}
                                            >
                                              次の期間に新しい端末を購入した利用者をターゲティング
                                            </TableCell>
                                            <TableCell align="left">
                                              {
                                                adInfo.others
                                                  .deviceActivationDuration
                                              }
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} css={accordionCell}>
              <ExpansionPanel
                defaultExpanded
                elevation={0}
                css={accordionCellPanel}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  css={accordionCellTitle}
                >
                  ターゲティング内容
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={accordionCellContent}>
                  <Table>
                    <TableBody>
                      {adInfo.others.broadMatchKeywords != null &&
                        adInfo.others.broadMatchKeywords.length !== 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row" css={tableTh}>
                              キーワード
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {adInfo.others.broadMatchKeywords.join(",")}
                            </TableCell>
                          </TableRow>
                        )}
                      {adInfo.others.phraseKeywordsExcluded != null &&
                        adInfo.others.phraseKeywordsExcluded.length !== 0 && (
                          <TableRow>
                            <TableCell colSpan={2} css={accordionCell}>
                              <ExpansionPanel
                                elevation={0}
                                css={accordionCellPanel}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  css={accordionCellTitle}
                                >
                                  除外するキーワード
                                </ExpansionPanelSummary>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  css={tableTh}
                                >
                                  除外するキーワード
                                </TableCell>

                                <TableCell align="left">
                                  {" "}
                                  {adInfo.others.phraseKeywordsExcluded.join(
                                    ","
                                  )}
                                </TableCell>
                              </ExpansionPanel>
                            </TableCell>
                          </TableRow>
                        )}
                      {adInfo.others.eventTargetingIds != null &&
                        adInfo.others.eventTargetingIds.length !== 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row" css={tableTh}>
                              イベント
                            </TableCell>
                            <TableCell align="left">
                              {adInfo.others.eventTargetingIds.join(",")}
                            </TableCell>
                          </TableRow>
                        )}
                      {adInfo.others.userInterests != null &&
                        adInfo.others.userInterests.length !== 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row" css={tableTh}>
                              ユーザーの興味関心
                            </TableCell>
                            <TableCell align="left">
                              {adInfo.others.userInterests.join(",")}
                            </TableCell>
                          </TableRow>
                        )}
                      {adInfo.others.followerTargetingLookalikes != null &&
                        adInfo.others.followerTargetingLookalikes.length !==
                          0 && (
                          <TableRow>
                            <TableCell component="th" scope="row" css={tableTh}>
                              フォロワー類似オーディエンス
                            </TableCell>

                            <TableCell align="left">
                              {" "}
                              {adInfo.others.followerTargetingLookalikes.join(
                                ","
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      {adInfo.others.tvShows != null &&
                        adInfo.others.tvShows.length !== 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row" css={tableTh}>
                              映画とテレビ番組
                            </TableCell>
                            <TableCell align="left">
                              {adInfo.others.tvShows.join(",")}
                            </TableCell>
                          </TableRow>
                        )}
                      {adInfo.others.conversations != null &&
                        adInfo.others.conversations.length !== 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row" css={tableTh}>
                              会話
                            </TableCell>
                            <TableCell align="left">
                              {adInfo.others.conversations.join(",")}
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              リターゲティング設定
            </TableCell>
            <TableCell align="left">
              {adInfo.others.retargetingType === "Tweet_Impression" && (
                <div>・ツイートの表示歴があるユーザー</div>
              )}
              {adInfo.others.retargetingType ===
                "Tweet_Impression_and_Engagement" && (
                <div>
                  ・ツイートの表示歴およびエンゲージメント歴があるユーザー
                </div>
              )}
              {/* retargetingEngagementType */}
              {adInfo.others.retargetingEngagementType === "IMPRESSION" && (
                <div>・インプレッション</div>
              )}
              {adInfo.others.retargetingEngagementType === "ENGAGEMENT" && (
                <div>・すべてのエンゲージメント</div>
              )}
              {adInfo.others.retargetingEngagementType === "VIDEO_VIEW" && (
                <div>・動画の再生数</div>
              )}
              {adInfo.others.retargetingEngagementType ===
                "VIDEO_PLAYBACK_PARTIAL" && <div>・50％動画再生完了</div>}
              {adInfo.others.retargetingEngagementType ===
                "VIDEO_PLAYBACK_COMPLETE" && <div>・動画再生完了</div>}
              {/* tweetType */}
              {adInfo.others.tweetType === "Organic" && (
                <div>・オーガニックツイート</div>
              )}
              {adInfo.others.tweetType === "Campaign" && (
                <div>・特定のキャンペーンに含まれたツイート</div>
              )}
              {adInfo.others.tweetType === "OrganicAndCampaign" && (
                <div>
                  ・オーガニックツイートおよび特定のキャンペーンに含まれたツイート
                </div>
              )}
              {/* campaignRetargeting */}
              {adInfo.others.campaignRetargeting != null &&
                adInfo.others.campaignRetargeting.length !== 0 && (
                  <div>
                    ・指定されたキャンペーン{" "}
                    {adInfo.others.campaignRetargeting.join(",")}
                  </div>
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              オーディエンスを広げる
            </TableCell>
            <TableCell align="left">
              {adInfo.others.lookalikeExpansionSetting}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              クリエイティブ番号
            </TableCell>
            <TableCell align="left">
              {adInfo.others.tweetIds.map((tw) => {
                return <div key={tw}>{tw}</div>
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              クリエイティブ指定
            </TableCell>
            <TableCell align="left">
              {adInfo.others.mediaCreativeIds?.join(",")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" css={tableTh}>
              ツイート表示場所
            </TableCell>
            <TableCell align="left">
              {adInfo.adGroupPlacementValues.join(", ")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ExpansionPanelDetails>
  )
}

const adsGroupContent = css`
  padding: 0;
`
const accordionCell = css`
  padding: 0;
`
const accordionCellTitle = css`
  padding-left: 16px;
`
const tableTh = css`
  width: 240px;
`
const accordionCellPanel = css`
  border-radius: 0;
  &:before {
    display: none;
  }
`
const accordionCellContent = css`
  padding: 0 0 0 40px;
`
const nestedTable = css`
  padding: 0;
  tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }
`
