import { Checkbox } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import React from "react"

type OwnProps = {
  checked: boolean
  children?: never
  disabled?: boolean
  label: string
  onSetValue: (nextChecked: boolean) => void
}

/**
 * ラベル付きチェックボックス
 */
export const LabeledCheckbox: React.FC<OwnProps> = ({
  checked,
  label,
  onSetValue,
  disabled,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => {
            onSetValue(e.target.checked)
          }}
        />
      }
      label={label}
      disabled={disabled}
    />
  )
}
