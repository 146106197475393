import * as CognitoAPI from "src/dataLayer/apis/CognitoAPI"
import { InhouseUserRoleValues } from "src/domainLayer/models/UserInfo"
import { AppThunkAction } from "src/types/ReduxTypes"

import * as actions from "./actions"

/**
 * ユーザー情報を取得
 */
export const fetchUserInfo = (): AppThunkAction<void> => {
  return async (dispatch) => {
    dispatch(actions.fetchUserInfo.started())

    let result
    try {
      result = await CognitoAPI.fetchUserInfo()
    } catch (error) {
      console.error(error)
      actions.fetchUserInfo.failed({ error: undefined })
      return
    }

    // Cognito のオペレーションミスで、role が正しくない場合
    if (!InhouseUserRoleValues.includes(result.user_role)) {
      console.error(`Unexpected user_role: ${result.user_role}`)
      actions.fetchUserInfo.failed({ error: undefined })
      return
    }

    dispatch(
      actions.fetchUserInfo.done({
        result: {
          userRole: result.user_role,
        },
      })
    )
  }
}
